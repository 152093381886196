import React from "react";
import "./Header_Company.css";
const Header_Company = ({ title, subTitle, img, info }) => {
  return (
    <div className="headerContainer">
      <div style={{ display: "flex", width: "80%" }}>
        <div className="info">
          <div>
            <h2>{title}</h2>
            <span>{subTitle}</span>
          </div>
          <div>
            <h3>{info}</h3>
          </div>
          <div></div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Header_Company;
