import { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { AccountContext } from "./AccountContext";
import Home from "../Pages/Home/Home";
import Members from "../Pages/Members/Members";
import TrainingPlan from "../Pages/TrainingPlans/TrainingPlanDetails";
import Login from "./Login/Login";
import PrivateRoutes from "./PrivateRoutes";
import MemberDetails from "../Pages/Members/MemberDetails";
import WeekPlanDetails from "../Pages/TrainingPlans/WeekplanDetails/WeekplanDetails";
import MembersAdd from "../Pages/Members/MembersAdd";
import MembersEdit from "../Pages/Members/MembersEdit";
import TrainingPlanAdd from "../Pages/TrainingPlans/TrainingPlanAdd";
import Exercises from "../Pages/Excercises/Exercises";
import ExerciseDetails from "../Pages/Excercises/ExerciseDetails";
import ExerciseAdd from "../Pages/Excercises/ExerciseAdd";
import ExerciseEdit from "../Pages/Excercises/ExerciseEdit";
import TrainingPlansForSale from "../Pages/TrainingPrograms/TrainingPrograms";
import AboutDetail from "../Pages/aboutMeAndCompany/AboutDetail";
import Contact from "../Pages/Contact/Contact";
import HomeSuperAdmin from "../Pages/Home/HomeSuperAdmin";
import AdminsAdd from "../Pages/Admins/AdminsAdd";
import AdminsEdit from "../Pages/Admins/AdminsEdit";

const Views = () => {
  const { user } = useContext(AccountContext);
  return user.loggedIn === null ? (
    <h1>Loading...</h1>
  ) : (
    <Routes>
      <Route exact path="/login" element={<Login />} />
      <Route element={<PrivateRoutes />}>
        <Route exact path="/homeSuperAdmin" element={<HomeSuperAdmin />} />
        <Route exact path="/home" element={<Home />} />

        <Route exact path="/admins/add" element={<AdminsAdd />} />
        <Route exact path="/admins/edit/:userId" element={<AdminsEdit />} />

        <Route exact path="/members" element={<Members />} />
        <Route path="/members/:userId" element={<MemberDetails />} />
        <Route exact path="/members/add" element={<MembersAdd />} />
        <Route exact path="/members/edit/:userId" element={<MembersEdit />} />

        <Route exact path="/exercises" element={<Exercises />} />
        <Route path="/exercises/:exerciseId" element={<ExerciseDetails />} />
        <Route exact path="/exercises/add" element={<ExerciseAdd />} />
        <Route
          exact
          path="/exercises/edit/:exerciseId"
          element={<ExerciseEdit />}
        />
        <Route
          exact
          path="/trainingPrograms"
          element={<TrainingPlansForSale />}
        />

        <Route
          path="/trainingProgram/:trainingProgramId"
          element={<TrainingPlan />}
        />
        <Route path="/trainingProgram/add" element={<TrainingPlanAdd />} />

        <Route path="/contact" element={<Contact />} />
        <Route path="/AboutMe" element={<AboutDetail />} />

        <Route path="/weekPlan/:weekPlanId" element={<WeekPlanDetails />} />
      </Route>
      <Route path="*" element={<Login />} />
    </Routes>
  );
};

export default Views;
