import React, { useEffect, useState, useContext } from "react";
import ADD_DETAIL_HEADER from "../../components/Add_Detail_Header";
import Sidebar from "../../components/Menu/Sidebar";
import "../aboutMeAndCompany/aboutDetail.css";
import { BsPencilFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { AccountContext } from "../../routes/AccountContext";
import AboutModal from "../../components/About/AboutModal";

function AboutDetail() {
  const [me, setMe] = useState([]);
  const [company, setCompany] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const [showModalMe, setShowModalMe] = useState(false);
  const [showModalPass, setShowModalPass] = useState(false);
  const [showModalProfile, setShowModalProfile] = useState(false);
  const [showModalCompany, setShowModalCompany] = useState(false);
  const [showModalCompanyLogo, setShowModalCompanyLogo] = useState(false);
  const [infoVisible, setinfoVisible] = useState(false);

  const { user } = useContext(AccountContext);
  const navigate = useNavigate();

  const [emailMe, setEmailMe] = useState("");
  const [phoneMe, setPhoneMe] = useState("");
  const [profilePicture, setProfilePicture] = useState();
  const [specializationMe, setSpecializationMe] = useState([]);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [logo, setLogo] = useState();
  const [emailCompany, setEmailCompany] = useState("");
  const [phoneCompany, setPhoneCompany] = useState("");
  const [websiteCompany, setWebsiteCompany] = useState("");
  const [appointmentCompany, setAppointmentCompany] = useState("");
  const [openingHoursCompany, setOpeningHoursCompany] = useState([]);
  const [adresCompany, setAdresCompany] = useState("");

  const updateCompany = async () => {
    const route = company?.id;

    try {
      fetch(`${process.env.REACT_APP_API_MAIN_URL}about/companie/${route}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${user?.token}`,
        },
        body: JSON.stringify({
          name: company.name,
          website: websiteCompany,
          email: emailCompany,
          phone: phoneCompany,
          appointment: appointmentCompany,
          openingHours: openingHoursCompany,
          adres: adresCompany,
        }),
      }).then((response) => {
        if (response.status === 200) {
          setShowModalCompany(false);
          alert("Your company info is updated");
        } else {
          alert("There went something wrong");
        }
      });
      setRefresh(true);
    } catch (err) {
      console.log(err.message);
    }
  };

  const updateMe = async () => {
    const route = me?.id;

    try {
      fetch(`${process.env.REACT_APP_API_MAIN_URL}about/me/${route}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${user?.token}`,
        },
        body: JSON.stringify({
          email: emailMe,
          phone: phoneMe,
          specializations: specializationMe,
        }),
      }).then((response) => {
        if (response.status === 200) {
          setShowModalMe(false);
          alert("Your profile info is updated");
        } else {
          alert("There went something wrong");
        }
      });
      setRefresh(true);
    } catch (err) {
      console.log(err.message);
    }
  };

  const updatePass = async () => {
    if (newPassword !== confirmPassword) {
      setErrorMessage("Your new and confirm password are not the same.");
    } else {
      try {
        fetch(`${process.env.REACT_APP_API_AUTH_URL}resetPasswordAdmin`, {
          method: "PUT",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${user?.token}`,
          },
          body: JSON.stringify({
            userid: user.user,
            oldPassword,
            newPassword,
            confirmPassword,
          }),
        }).then((response) => {
          if (response.status === 200) {
            setShowModalPass(false);
            alert("Your password is updated");
          } else {
            response.json().then((data) => alert(`${data.error}`));
          }
        });
      } catch (err) {
        console.log(err.message);
      }
    }
  };

  const updateProfilePicture = async () => {
    const route = me?.id;

    try {
      fetch(
        `${process.env.REACT_APP_API_MAIN_URL}about/profilePicture/${route}`,
        {
          method: "PUT",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${user?.token}`,
          },
          body: JSON.stringify({
            profilePicture,
          }),
        }
      ).then((response) => {
        if (response.status === 200) {
          setShowModalProfile(false);
          alert("Your profile picture is updated");
        } else {
          alert("There went something wrong");
        }
      });
      setRefresh(true);
    } catch (err) {
      console.log(err.message);
    }
  };

  const updateLogo = async () => {
    const route = company?.id;

    try {
      fetch(`${process.env.REACT_APP_API_MAIN_URL}about/logo/${route}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${user?.token}`,
        },
        body: JSON.stringify({
          logo,
        }),
      }).then((response) => {
        if (response.status === 200) {
          setShowModalCompanyLogo(false);
          alert("Your company logo is updated");
        } else {
          alert("There went something wrong");
        }
      });
      setRefresh(true);
    } catch (err) {
      console.log(err.message);
    }
  };

  //convert image to base64
  const uploadProfilePicture = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setProfilePicture(base64);
    setRefresh(true);
  };

  const uploadLogo = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setLogo(base64);
    //setRefresh(true);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    const getMe = async () => {
      try {
        const userId = user?.id;
        const response = await fetch(
          `${process.env.REACT_APP_API_MAIN_URL}about/${userId}`,
          {
            method: "GET",
            headers: {
              authorization: `Bearer ${user?.token}`,
            },
          }
        );
        const jsonData = await response.json();

        setMe(jsonData.admin);
        setEmailMe(jsonData.admin.email);
        setPhoneMe(jsonData.admin.phone);
        setSpecializationMe(jsonData.admin.specializations);
        setProfilePicture(jsonData.admin.profilePicture);

        setCompany(jsonData.company);
        setLogo(jsonData.company.logo);
        setEmailCompany(jsonData.company.email);
        setPhoneCompany(jsonData.company.phone);
        setWebsiteCompany(jsonData.company.website);
        setAppointmentCompany(jsonData.company.appointment);
        setOpeningHoursCompany(jsonData.company.openinghours);
        setAdresCompany(jsonData.company.adres);
        setRefresh(false);
      } catch (err) {
        console.log(err.message);
      }
    };

    getMe();
  }, [refresh]);

  return (
    <div className="layout">
      <Sidebar selected="About me" />
      <div className="layout__content">
        <div className="layout__content-main">
          <ADD_DETAIL_HEADER title={"Me and the company"} />
          <div style={{ marginTop: "50px" }}>
            <div className="contentHeaders">
              <span
                className={infoVisible ? "" : "spanActive"}
                onClick={() => setinfoVisible(false)}
              >
                About me
              </span>
              <span
                className={infoVisible ? "spanActive" : ""}
                onClick={() => setinfoVisible(true)}
              >
                Company Information
              </span>
            </div>
            {!infoVisible && (
              <div className="containerMe">
                <div className="logo">
                  <h2 style={{ marginBottom: "15px" }}>
                    <BsPencilFill
                      style={{ marginRight: "5px" }}
                      onClick={() => setShowModalProfile(true)}
                    />
                    Logo
                  </h2>
                  <div
                    className="memberPicture"
                    onClick={() => setShowModalProfile(true)}
                  >
                    {me?.profilepicture && (
                      <img alt="profilePicture" src={me?.profilepicture} />
                    )}
                  </div>
                </div>

                <div className="info">
                  <h2 style={{ marginTop: "10px", marginBottom: "15px" }}>
                    <BsPencilFill
                      style={{ marginRight: "5px" }}
                      onClick={() => setShowModalMe(true)}
                    />
                    Info
                  </h2>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span>Email: {me?.email}</span>
                    <span>Phone: {me?.phone}</span>
                    <span>Gender: {me?.gender ? "male" : "female"}</span>
                  </div>
                </div>
                <div className="resetPass">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <h4 onClick={() => setShowModalPass(true)}>
                      Reset My Password
                    </h4>
                  </div>
                </div>
                <div className="col2row1rowSp2">
                  <h2 style={{ marginBottom: "15px" }}>
                    <BsPencilFill
                      style={{ marginRight: "5px" }}
                      onClick={() => setShowModalMe(true)}
                    />
                    Specializations
                  </h2>
                  <ul>
                    {me?.specializations &&
                      me?.specializations.map((specialization, i) => (
                        <li key={i}> {specialization}</li>
                      ))}
                  </ul>
                </div>
              </div>
            )}
            {infoVisible && (
              <div className="containerMe">
                <div className="logo">
                  <h2 style={{ marginBottom: "15px" }}>
                    <BsPencilFill
                      style={{ marginRight: "5px" }}
                      onClick={() => setShowModalCompanyLogo(true)}
                    />
                    Logo
                  </h2>
                  <div
                    className="memberPicture"
                    onClick={() => setShowModalCompanyLogo(true)}
                  >
                    {company.logo && (
                      <img alt="companyLogo" src={company?.logo} />
                    )}
                  </div>
                </div>
                <div className="info">
                  <h2 style={{ marginBottom: "15px" }}>
                    <BsPencilFill
                      style={{ marginLeft: "5px" }}
                      onClick={() => setShowModalCompany(true)}
                    />
                    Info
                  </h2>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span>Email: {company.email}</span>
                    <span>Phone: {company.phone}</span>
                    <span>Website: {company.website}</span>
                  </div>
                </div>
                <div className="appointmentCompany">
                  <h2 style={{ marginBottom: "15px" }}>
                    <BsPencilFill
                      style={{ marginLeft: "5px" }}
                      onClick={() => setShowModalCompany(true)}
                    />
                    Make an appointment URL
                  </h2>
                  <span>
                    {company.appointment.length > 42
                      ? company.appointment.substring(0, 42) + "..."
                      : company.appointment}
                  </span>
                </div>
                <div className="col2row1rowSp2">
                  <h2 style={{ marginBottom: "15px" }}>
                    <BsPencilFill
                      style={{ marginLeft: "5px" }}
                      onClick={() => setShowModalCompany(true)}
                    />
                    Opening hours
                  </h2>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {company.openinghours ? (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <span>Mon. {company.openinghours[0] || "CLOSED"}</span>
                        <span>Tues. {company.openinghours[1] || "CLOSED"}</span>
                        <span>Wed. {company.openinghours[2] || "CLOSED"}</span>
                        <span>
                          Thurs. {company.openinghours[3] || "CLOSED"}
                        </span>
                        <span>Fri. {company.openinghours[4] || "CLOSED"}</span>
                        <span>Sat. {company.openinghours[5] || "CLOSED"}</span>
                        <span>Sun. {company.openinghours[6] || "CLOSED"}</span>
                      </div>
                    ) : (
                      <h2>No opening hours available.</h2>
                    )}
                  </div>
                </div>
                <div className="adresCompany">
                  <h2 style={{ marginBottom: "15px" }}>
                    <BsPencilFill
                      style={{ marginLeft: "5px" }}
                      onClick={() => setShowModalCompany(true)}
                    />
                    Adres
                  </h2>
                  <ul>
                    <li> {company.adres}</li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {showModalMe && (
        <AboutModal
          navigate={() => navigate("/aboutme")}
          handleSave={() => updateMe()}
          handleClose={() => setShowModalMe(false)}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            style={{ width: "100%" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "48%" }}>
                <div>
                  <h4>Email</h4>
                  <input
                    type="text"
                    placeholder="Email"
                    value={emailMe}
                    onChange={(e) => setEmailMe(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <h4>Phone</h4>
                  <input
                    type="text"
                    placeholder="Phone"
                    value={phoneMe}
                    onChange={(e) => setPhoneMe(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div style={{ width: "48%" }}>
                <div>
                  <h4>Specializations</h4>
                  <textarea
                    rows="5"
                    style={{ height: 200, minWidth: 500 }}
                    placeholder="Specializations"
                    value={specializationMe || ""}
                    onChange={(e) =>
                      setSpecializationMe(e.target.value.split(","))
                    }
                  />
                </div>
              </div>
            </div>
          </form>
        </AboutModal>
      )}
      {showModalPass && (
        <AboutModal
          navigate={() => navigate("/aboutme")}
          handleSave={() => {
            setErrorMessage("");
            updatePass();
          }}
          handleClose={() => setShowModalPass(false)}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            style={{ width: "100%" }}
          >
            <div>
              <h4>Old Password</h4>
              <input
                type="password"
                placeholder="OldPassword"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                required
              />
            </div>
            <div>
              <h4>New Password</h4>
              <input
                type="password"
                placeholder="NewPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </div>
            <div>
              <h4>Confirm Password</h4>
              <input
                type="password"
                placeholder="ConfirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
            {errorMessage && <span>{errorMessage}</span>}
          </form>
        </AboutModal>
      )}
      {showModalProfile && (
        <AboutModal
          navigate={() => navigate("/aboutme")}
          handleSave={() => updateProfilePicture()}
          handleClose={() => setShowModalProfile(false)}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            style={{ width: "100%" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div>
                <h4>Picture</h4>
                <input
                  className="inputFile"
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    uploadProfilePicture(e);
                  }}
                />
              </div>
              <img
                alt="profilePicture"
                src={profilePicture}
                style={{ maxHeight: "200px", maxWidth: "200px" }}
              />
            </div>
          </form>
        </AboutModal>
      )}

      {showModalCompany && (
        <AboutModal
          navigate={() => navigate("/aboutme")}
          handleSave={() => updateCompany()}
          handleClose={() => setShowModalCompany(false)}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            style={{ width: "100%" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "48%" }}>
                <div>
                  <h4>Email</h4>
                  <input
                    type="text"
                    placeholder="Email"
                    value={emailCompany}
                    onChange={(e) => setEmailCompany(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <h4>Phone</h4>
                  <input
                    type="text"
                    placeholder="Phone"
                    value={phoneCompany}
                    onChange={(e) => setPhoneCompany(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <h4>Website</h4>
                  <input
                    type="website"
                    placeholder="Website"
                    value={websiteCompany}
                    onChange={(e) => setWebsiteCompany(e.target.value)}
                    required
                  />
                </div>

                <div>
                  <h4>Appointment</h4>
                  <input
                    type="text"
                    placeholder="Appointment"
                    value={appointmentCompany}
                    onChange={(e) => setAppointmentCompany(e.target.value)}
                  />
                </div>
              </div>
              <div style={{ width: "48%" }}>
                <div style={{ width: "100%", height: "46%" }}>
                  <h4>Opening Hours</h4>
                  <textarea
                    rows="7"
                    style={{ height: "80%" }}
                    placeholder="Opening hours"
                    value={openingHoursCompany}
                    onChange={(e) =>
                      setOpeningHoursCompany(e.target.value.split(","))
                    }
                  />
                </div>
                <div>
                  <h4>Adres</h4>
                  <input
                    type="text"
                    placeholder="Adres"
                    value={adresCompany}
                    onChange={(e) => setAdresCompany(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>
          </form>
        </AboutModal>
      )}

      {showModalCompanyLogo && (
        <AboutModal
          navigate={() => navigate("/aboutme")}
          handleSave={() => {
            updateLogo();
            setShowModalCompanyLogo(false);
          }}
          handleClose={() => setShowModalCompanyLogo(false)}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            style={{ width: "100%" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div>
                <h4>Company Logo</h4>
                <input
                  className="inputFile"
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    uploadLogo(e);
                  }}
                />
              </div>
              <img
                alt="logo"
                src={logo}
                style={{ maxHeight: "200px", maxWidth: "200px" }}
              />
            </div>
          </form>
        </AboutModal>
      )}
    </div>
  );
}

export default AboutDetail;
