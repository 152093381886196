import React, { useContext, useEffect, useState } from "react";
import HEADER_COMPANY from "../../components/Members/Details/Header_Company";
import Sidebar from "../../components/Menu/Sidebar";
import { SearchBar, filterPosts } from "../../components/SearchBar/SearchBar";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

import "../../assets/css/table.css";
import { AccountContext } from "../../routes/AccountContext";

const TrainingPrograms = () => {
  const [trainingPrograms, setTrainingPrograms] = useState([]);
  const [info, setInfo] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [isMounted, setIsMounted] = useState(true); // Track component mount state
  const { search } = window.location;
  const query = new URLSearchParams(search).get("s");
  const [searchQuery, setSearchQuery] = useState(query || "");
  const filteredPosts = filterPosts(trainingPrograms, searchQuery);
  const navigate = useNavigate();
  const { user } = useContext(AccountContext);

  const updateTPVisable = async (id, visible) => {
    try {
      await fetch(
        `${process.env.REACT_APP_API_MAIN_URL}trainingprograms/trainingProgramVisable/${id}`,
        {
          method: "PUT",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${user?.token}`,
          },
          body: JSON.stringify({
            forSaleVisible: visible,
          }),
        }
      );
    } catch (err) {
      console.log(err.message);
    }
  };

  const deleteTP = async (id) => {
    try {
      await fetch(
        `${process.env.REACT_APP_API_MAIN_URL}trainingPrograms/${id}`,
        {
          method: "DELETE",
          credentials: "include",
          headers: {
            authorization: `Bearer ${user?.token}`,
          },
        }
      ).then((response) =>
        response.status === 200
          ? setRefresh(true)
          : alert("Something went wrong")
      );
    } catch (err) {
      console.log(err.message);
    }
    deletePdfs(id);
  };

  const deletePdfs = async (id) => {
    try {
      await fetch(
        `${process.env.REACT_APP_API_MAIN_URL}pdfs/trainingprogram/${id}`,
        {
          method: "DELETE",
          credentials: "include",
          headers: { authorization: `Bearer ${user?.token}` },
        }
      ).then((response) => {
        if (response.status !== 200) {
          alert("There went something wrong");
        }
      });
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    const getTrainingPrograms = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_MAIN_URL}trainingPrograms/trainingProgramsForSale/${user?.user}`,
          {
            method: "GET",
            headers: {
              authorization: `Bearer ${user?.token}`,
            },
          }
        );
        const jsonData = await response.json();
        if (isMounted) {
          setTrainingPrograms(jsonData);
        }
      } catch (err) {
        console.log(err.message);
      }
    };

    const getInfo = async () => {
      try {
        const userId = user?.id;
        const response = await fetch(
          `${process.env.REACT_APP_API_MAIN_URL}about/${userId}`,
          {
            method: "GET",
            headers: {
              authorization: `Bearer ${user?.token}`,
            },
          }
        );
        const jsonData = await response.json();
        if (isMounted) {
          setInfo(jsonData);
        }
      } catch (err) {
        console.log(err.message);
      }
    };

    getTrainingPrograms();
    getInfo();

    // Cleanup function
    return () => {
      setIsMounted(false);
    };
  }, [user?.user, user?.token, isMounted, refresh]);

  return (
    <div className="layout">
      <Sidebar selected="Training programs" />
      <div className="layout__content">
        <div className="layout__content-main">
          <HEADER_COMPANY
            title={info.company?.name}
            subTitle={"Admin: " + user.userName}
            info={"Training programs: " + trainingPrograms.length}
          />
          <div>
            <div className="fr">
              <SearchBar
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                placeholder="Search by name"
                btnName="Add Training program"
                btnOnClick={() => {
                  navigate("/trainingProgram/add", {
                    state: { forSale: true, page: "ForSale" },
                  });
                }}
              />
            </div>
            <div className="tableWrapper">
              <div className="table_overflow">
                <table>
                  <tbody>
                    <tr>
                      <th>Name</th>
                      <th>weeks</th>
                      <th>Price</th>
                      <th>Visible/Delete</th>
                    </tr>
                    {filteredPosts &&
                      filteredPosts.length > 0 &&
                      filteredPosts.map((post, i) => (
                        <tr key={post.id}>
                          <td
                            onClick={() => {
                              const trainingPlanId = post.id;
                              navigate(`/TrainingProgram/${trainingPlanId}`, {
                                state: {
                                  forSale: true,
                                },
                              });
                            }}
                          >
                            {post.name}
                          </td>
                          <td
                            onClick={() => {
                              const trainingPlanId = post.id;
                              navigate(`/TrainingProgram/${trainingPlanId}`, {
                                state: {
                                  forSale: true,
                                },
                              });
                            }}
                          >
                            {post.weeks}
                          </td>
                          <td
                            onClick={() => {
                              const trainingPlanId = post.id;
                              navigate(`/TrainingProgram/${trainingPlanId}`, {
                                state: {
                                  forSale: true,
                                },
                              });
                            }}
                          >
                            {post.price ? post.price : 0}
                          </td>
                          <td>
                            {!post.forsalevisible ? (
                              <FaEye
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  updateTPVisable(post.id, true);
                                }}
                              />
                            ) : (
                              <FaEyeSlash
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  updateTPVisable(post.id, false);
                                  setRefresh(true);
                                }}
                              />
                            )}
                            <MdDelete onClick={() => deleteTP(post.id)} />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainingPrograms;
