import React from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import { BsPencilFill } from "react-icons/bs";

import { useNavigate } from "react-router-dom";

const Add_Detail_Header = ({ title, editOnClick, closeOnClick }) => {
  const navigate = useNavigate();

  return (
    <div className="detailHeader">
      <div>
        {!closeOnClick && (
          <IoArrowBackOutline onClick={() => navigate(-1)} size="30px" />
        )}
      </div>
      <div>
        <h1>{title}</h1>
      </div>
      <div>
        {editOnClick && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <h4 onClick={() => editOnClick()}>Edit</h4>
            <BsPencilFill style={{ marginLeft: "5px" }} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Add_Detail_Header;
