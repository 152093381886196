import './message.css';
//import { format } from "timeago.js";
import { MdAccountCircle } from 'react-icons/md';

const Message = ({ message, own, profilePic }) => {
  return (
    <div className={own ? 'message own' : 'message'}>
      <div className='messageTop'>
        {profilePic ? (
          <img className='messageImg' src={profilePic} alt='' />
        ) : (
          <MdAccountCircle className='messageImg' />
        )}
        <p className='messageText'>{message.text}</p>
      </div>
      <div className='messageBottom'>{/*format(message.timestamp)*/}</div>
    </div>
  );
};

export default Message;
