import React, { useContext, useEffect, useState } from "react";
import HEADER_COMPANY from "../../components/Members/Details/Header_Company";
import Sidebar from "../../components/Menu/Sidebar";
import { SearchBar, filterPosts } from "../../components/SearchBar/SearchBar";
import { useNavigate } from "react-router-dom";
import { AccountContext } from "../../routes/AccountContext";

import "../../assets/css/table.css";
import "./Members.css";

const Members = () => {
  const [members, setMembers] = useState([]);
  const { search } = window.location;
  const query = new URLSearchParams(search).get("s");
  const [searchQuery, setSearchQuery] = useState(query || "");
  const filteredPosts = filterPosts(members, searchQuery, "member");
  const { user } = useContext(AccountContext);

  const navigate = useNavigate();

  useEffect(() => {
    const getMembers = async () => {
      try {
        const userId = user.user;
        const response = await fetch(
          `${process.env.REACT_APP_API_MAIN_URL}members/${userId}`,
          {
            method: "GET",
            headers: {
              authorization: `Bearer ${user?.token}`,
            },
          }
        );
        const jsonData = await response.json();
        setMembers(jsonData);
      } catch (err) {
        console.log(err.message);
      }
    };

    getMembers();
  }, []);

  return (
    <div className="layout">
      <Sidebar selected="Members" />
      <div className="layout__content">
        <div className="layout__content-main">
          <HEADER_COMPANY
            title={user.company}
            subTitle={"Admin: " + user.userName}
            info={`Members: ${members.length}`}
          />
          <div>
            <SearchBar
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              placeholder="Search by name"
              btnName="Add member"
              btnOnClick={() => navigate("/members/add")}
            />
            <div className="tableWrapper">
              <div className="table_overflow">
                <table>
                  <tbody>
                    <tr>
                      <th>First name</th>
                      <th>Last name</th>
                      <th>Last update</th>
                      <th>Last week</th>
                      <th>Comments</th>
                    </tr>

                    {filteredPosts &&
                      filteredPosts.map((post, i) => (
                        <tr
                          key={post.userid}
                          onClick={() => {
                            const userId = post.userid;
                            navigate(`/members/${userId}`);
                          }}
                        >
                          <td>{post.firstname}</td>
                          <td>{post.lastname}</td>
                          <td>{post.lastupdate || "/"}</td>
                          <td>{post.lastweek || "/"}</td>
                          <td>{post.remarks || 0}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Members;
