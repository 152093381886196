import React, { useContext, useState } from "react";
import ADD_DETAIL_HEADER from "../../components/Add_Detail_Header";
import Sidebar from "../../components/Menu/Sidebar";
import "../../assets/css/form.css";
import MemberAddEditDeleteModal from "../../components/Members/MemberAddEditDeleteModal";
import { useNavigate } from "react-router-dom";
import { AccountContext } from "../../routes/AccountContext";

function AdminsAdd() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    gender: "true",
    specializations: "",

    companyName: "",
    companyWebsite: "",
    companyEmail: "",
    companyPhone: "",
    companyAppointment: "",
    companyAdres: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [userCode, setUserCode] = useState("");

  const navigate = useNavigate();
  const { user } = useContext(AccountContext);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const createAdmin = async () => {
    setLoading(true);

    try {
      const userId = user.user;
      fetch(`${process.env.REACT_APP_API_AUTH_URL}signupAdmin`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          userId,
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          phone: formData.phone,
          gender: formData.gender,
          specializations: formData.specializations,

          companyName: formData.companyName,
          companyWebsite: formData.companyWebsite,
          companyEmail: formData.companyEmail,
          companyPhone: formData.companyPhone,
          companyAppointment: formData.companyAppointment,
          companyAdres: formData.companyAdres,
        }),
      })
        .then((response) => response.json())
        .then((json) => {
          setUserCode(json.code);
          setShowModal(true);
          setLoading(false);
        });
    } catch (err) {
      alert("something went wrong");
    }
    setLoading(false);
  };

  return (
    <div className="layout">
      <Sidebar selected="Members" />
      <div className="layout__content">
        <div className="layout__content-main">
          <ADD_DETAIL_HEADER title={"Let's create a new Coach"} />

          <form
            onSubmit={(e) => {
              createAdmin();
              e.preventDefault();
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ width: "48%" }}>
                <h4>First name</h4>
                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div style={{ width: "48%" }}>
                <h4>Last name</h4>
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
            <div>
              <h4>Email</h4>
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </div>
            <div>
              <h4>Phone</h4>
              <input
                type="tel"
                name="phone"
                placeholder="Phone"
                value={formData.phone}
                onChange={handleInputChange}
                required
              />
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ width: "40%" }}>
                <h4>Gender</h4>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    className="radio"
                    type="radio"
                    name="gender"
                    checked={formData.gender === "true"}
                    value={true}
                    onChange={handleInputChange}
                  ></input>
                  <label>Male</label>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    className="radio"
                    type="radio"
                    name="gender"
                    checked={formData.gender === "false"}
                    value={false}
                    onChange={handleInputChange}
                  ></input>
                  <label>Female</label>
                </div>
              </div>
            </div>
            <div>
              <h4>Specializations</h4>
              <input
                type="text"
                name="specializations"
                placeholder="Split by a ','"
                value={formData.specializations}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <h4>Company Name</h4>
              <input
                type="text"
                name="companyName"
                placeholder="Company Name"
                value={formData.companyName}
                onChange={handleInputChange}
                required
              />
            </div>
            <div>
              <h4>Website</h4>
              <input
                type="text"
                name="companyWebsite"
                placeholder="Website"
                value={formData.companyWebsite}
                onChange={handleInputChange}
                required
              />
            </div>
            <div>
              <h4>Email</h4>
              <input
                type="email"
                name="companyEmail"
                placeholder="Email"
                value={formData.companyEmail}
                onChange={handleInputChange}
                required
              />
            </div>
            <div>
              <h4>Phone</h4>
              <input
                type="tel"
                name="companyPhone"
                placeholder="Phone"
                value={formData.companyPhone}
                onChange={handleInputChange}
                required
              />
            </div>
            <div>
              <h4>Appointment</h4>
              <input
                type="text"
                name="companyAppointment"
                placeholder="Appointment"
                value={formData.companyAppointment}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <h4>Adres</h4>
              <input
                type="text"
                name="companyAdres"
                placeholder="Adres"
                value={formData.companyAdres}
                onChange={handleInputChange}
                required
              />
            </div>
            <button type="submit">Save {loading && <p>LOADING...</p>}</button>
          </form>
        </div>
      </div>
      {showModal && (
        <MemberAddEditDeleteModal
          navigate={() => navigate("/homeSuperAdmin")}
          handleClose={() => setShowModal(false)}
        >
          <h1>A new coach has been created!</h1>
          <p> Password: {userCode}</p>
          <p>An email with the code has been sent to the coach</p>
        </MemberAddEditDeleteModal>
      )}
    </div>
  );
}

export default AdminsAdd;
