import React, { useContext, useEffect, useState } from "react";
import ADD_DETAIL_HEADER from "../../components/Add_Detail_Header";
import Sidebar from "../../components/Menu/Sidebar";
import "../../assets/css/form.css";
import { useNavigate, useParams } from "react-router-dom";
import MemberAddEditDeleteModal from "../../components/Members/MemberAddEditDeleteModal";
import { AccountContext } from "../../routes/AccountContext";

const AdminsEdit = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    gender: "true",
    specializations: "",

    companyName: "",
    companyWebsite: "",
    companyEmail: "",
    companyPhone: "",
    companyAppointment: "",
    companyAdres: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [showModalResetPass, setShowModalResetPass] = useState(false);
  const [loading, setLoading] = useState(true);

  let params = useParams();
  const navigate = useNavigate();
  const { user } = useContext(AccountContext);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const updateMember = async () => {
    const userId = params.userId;
    try {
      fetch(
        `${process.env.REACT_APP_API_MAIN_URL}homeSuperAdmin/admin/${userId}`,
        {
          method: "PUT",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify({
            firstname: formData.firstName,
            lastname: formData.lastName,
            email: formData.email,
            phone: formData.phone,
            gender: formData.gender,
            specializations: formData.specializations,

            companyName: formData.companyName,
            companyWebsite: formData.companyWebsite,
            companyEmail: formData.companyEmail,
            companyPhone: formData.companyPhone,
            companyAppointment: formData.companyAppointment,
            companyAdres: formData.companyAdres,
          }),
        }
      ).then(setShowModal(true));
    } catch (err) {
      alert("something went wrong");
    }
  };

  const resetPass = async () => {
    try {
      fetch(`${process.env.REACT_APP_API_AUTH_URL}resetPasswordAdmin`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          userid: params?.userId,
          superAdmin: true,
        }),
      }).then((responce) => {
        if (responce.status === 200) {
          alert("Password updated");
        } else {
          alert("Something went wrong");
        }
      });
    } catch (err) {
      alert("something went wrong");
    }
  };

  useEffect(() => {
    const getMember = async () => {
      let formData = {};
      try {
        const route = params?.userId;
        const response = await fetch(
          `${process.env.REACT_APP_API_MAIN_URL}homeSuperAdmin/admin/${route}`,
          {
            method: "GET",
            headers: {
              authorization: `Bearer ${user?.token}`,
            },
          }
        );
        const jsonData = await response.json();

        formData.firstName = jsonData.admin?.firstname;
        formData.lastName = jsonData.admin?.lastname;
        formData.email = jsonData.admin?.email;
        formData.phone = jsonData.admin?.phone;
        formData.gender = jsonData.admin?.gender.toString();
        formData.specializations = jsonData.admin?.specializations;

        formData.companyName = jsonData.company?.name;
        formData.companyWebsite = jsonData.company?.website;
        formData.companyEmail = jsonData.company?.email;
        formData.companyPhone = jsonData.company?.phone;
        formData.companyAppointment = jsonData.company?.appointment;
        formData.companyAdres = jsonData.company?.adres;
        setFormData(formData);
        setLoading(false);
      } catch (err) {}
    };

    getMember();
  }, []);

  return loading ? (
    <h2>Loading</h2>
  ) : (
    <div className="layout">
      <Sidebar selected="Members" />
      <div className="layout__content">
        <div className="layout__content-main">
          <ADD_DETAIL_HEADER title={"Let's edit this member"} />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              updateMember();
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ width: "48%" }}>
                <h4>First name</h4>
                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div style={{ width: "48%" }}>
                <h4>Last name</h4>
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
            <div>
              <h4>Email</h4>
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={(e) => handleInputChange}
                required
              />
            </div>
            <div>
              <h4>Phone</h4>
              <input
                type="tel"
                name="phone"
                placeholder="Phone"
                value={formData.phone}
                onChange={handleInputChange}
                required
              />
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ width: "40%" }}>
                <h4>Gender</h4>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    className="radio"
                    type="radio"
                    name="gender"
                    checked={formData.gender === "true"}
                    value={true}
                    onChange={handleInputChange}
                  ></input>
                  <label style={{ marginBottom: "25px", marginLeft: "10px" }}>
                    Male
                  </label>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    className="radio"
                    type="radio"
                    name="gender"
                    checked={formData.gender === "false"}
                    value={false}
                    onChange={handleInputChange}
                  ></input>
                  <label style={{ marginBottom: "25px", marginLeft: "10px" }}>
                    Female
                  </label>
                </div>
              </div>
            </div>
            <div>
              <h4>Company Name</h4>
              <input
                type="text"
                name="companyName"
                placeholder="Company name"
                value={formData.companyName}
                onChange={handleInputChange}
                required
              />
            </div>
            <div>
              <h4>Website</h4>
              <input
                type="text"
                name="companyWebsite"
                placeholder="Website"
                value={formData.companyWebsite}
                onChange={handleInputChange}
                required
              />
            </div>
            <div>
              <h4>Email</h4>
              <input
                type="email"
                name="companyEmail"
                placeholder="Email"
                value={formData.companyEmail}
                onChange={handleInputChange}
                required
              />
            </div>
            <div>
              <h4>Phone</h4>
              <input
                type="tel"
                name="companyPhone"
                placeholder="Phone"
                value={formData.companyPhone}
                onChange={handleInputChange}
                required
              />
            </div>
            <div>
              <h4>Appointment</h4>
              <input
                type="text"
                name="companyAppointment"
                placeholder="Appointment"
                value={formData.companyAppointment}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <h4>Adres</h4>
              <input
                type="text"
                name="companyAdres"
                placeholder="Adres"
                value={formData.companyAdres}
                onChange={handleInputChange}
                required
              />
            </div>
            <div style={{ display: "flex" }}>
              <button className="formButton" type="submit">
                Save
              </button>
              <button
                className="formButton"
                onClick={() => setShowModalResetPass(true)}
              >
                Reset Password
              </button>
            </div>
          </form>
          {showModal && (
            <MemberAddEditDeleteModal
              navigate={() => navigate("/homeSuperAdmin")}
              handleClose={() => setShowModal(false)}
            >
              <h1>The coach has been editted!</h1>
            </MemberAddEditDeleteModal>
          )}
          {showModalResetPass && (
            <div className="modal__backdrop">
              <div className="modal__content">
                <h2>Are you sure you want to reset the coach's password?</h2>
                <div>
                  <button
                    onClick={() => {
                      resetPass();
                      setShowModalResetPass(false);
                    }}
                  >
                    Yes
                  </button>
                  <button onClick={() => setShowModalResetPass(false)}>
                    No
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminsEdit;
