import React from "react";
import "./aboutModal.css";

const aboutModal = ({ children, handleSave, handleClose, navigate }) => {
  return (
    <div className="modal__backdrop">
      <div className="modal__content">
        {children}
        <div>
          <button onClick={() => handleSave()}>Save</button>
          <button onClick={handleClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default aboutModal;
