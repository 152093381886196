import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../components/Menu/Sidebar";
import "../../assets/css/form.css";
import ADD_DETAIL_HEADER from "../../components/Add_Detail_Header";
import MemberAddEditDeleteModal from "../../components/Members/MemberAddEditDeleteModal";
import { AccountContext } from "../../routes/AccountContext";

const MembersEdit = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const [length, setLength] = useState(0);
  const [sport, setSport] = useState("");
  const [weight, setWeight] = useState(0);
  const [vo2, setVo2] = useState("");
  const [showModal, setShowModal] = useState(false);
  const { user } = useContext(AccountContext);
  let params = useParams();
  const navigate = useNavigate();

  const updateMember = async () => {
    const userId = params.userId;
    try {
      fetch(
        `${process.env.REACT_APP_API_MAIN_URL}members/memberProfile/${userId}`,
        {
          method: "PUT",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${user?.token}`,
          },
          body: JSON.stringify({
            firstname: firstName,
            lastname: lastName,
            email: email,
            phone: phone,
            gender: gender,
            sport: sport,
            length: length,
            weight: weight,
            vo2max: vo2,
          }),
        }
      ).then((response) => {
        if (response.status === 200) {
          alert("The new member is added");
          navigate("/members");
        } else {
          alert("There went something wrong");
        }
      });
    } catch (err) {
      alert("something went wrong");
      console.log(err.message);
    }
  };

  useEffect(() => {
    const getMember = async () => {
      try {
        const route = params.userId;
        const response = await fetch(
          `${process.env.REACT_APP_API_MAIN_URL}members/member/${route}`,
          {
            method: "GET",
            headers: {
              authorization: `Bearer ${user?.token}`,
            },
          }
        );
        const jsonData = await response.json();
        setFirstName(jsonData.member?.firstname);
        setLastName(jsonData.member?.lastname);
        setEmail(jsonData.member?.email);
        setPhone(jsonData.member?.phone);
        setGender(jsonData.member?.gender);
        setSport(jsonData.member?.sport);
        setLength(jsonData.member?.length);
        setWeight(jsonData.member?.weight);
        setVo2(jsonData.member?.vo2max);
      } catch (err) {
        console.log(err.message);
      }
    };

    getMember();
  }, []);

  return (
    <div className="layout">
      <Sidebar selected="Members" />
      <div className="layout__content">
        <div className="layout__content-main">
          <ADD_DETAIL_HEADER title={"Let's edit this member"} />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              updateMember();
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ width: "48%" }}>
                <h4>First name</h4>
                <input
                  type="text"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </div>
              <div style={{ width: "48%" }}>
                <h4>Last name</h4>
                <input
                  type="text"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </div>
            </div>
            <div>
              <h4>Email</h4>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div>
              <h4>Phone</h4>
              <input
                type="tel"
                placeholder="Phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ width: "40%" }}>
                <h4>Gender</h4>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "25",
                  }}
                >
                  <input
                    className="radio"
                    type="radio"
                    style={{ width: "50%", marginBottom: "0" }}
                    checked={gender === "Male"}
                    value="Male"
                    onChange={(e) => {
                      setGender(e.target.value);
                    }}
                  />
                  <label style={{ marginLeft: "10px" }}>Male</label>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "25",
                  }}
                >
                  <input
                    className="radio"
                    type="radio"
                    style={{ width: "50%", marginBottom: "0" }}
                    checked={gender === "Female"}
                    value="Female"
                    onChange={(e) => {
                      setGender(e.target.value);
                    }}
                  />
                  <label style={{ marginLeft: "10px" }}>Female</label>
                </div>
              </div>
              <div style={{ width: "60%" }}>
                <h4>Optional</h4>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="fsba">
                    <h4>Length</h4>
                    <input
                      type="number"
                      placeholder="0 cm"
                      value={length}
                      onChange={(e) => setLength(e.target.value)}
                    />
                  </div>
                  <div className="fsba">
                    <h4>Weigth</h4>
                    <input
                      type="number"
                      placeholder="0 kilo"
                      value={weight}
                      onChange={(e) => setWeight(e.target.value)}
                    />
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="fsba">
                    <h4 style={{ marginBottom: "25px" }}>Sport</h4>
                    <input
                      type="text"
                      placeholder="Sport"
                      value={sport}
                      onChange={(e) => setSport(e.target.value)}
                    />
                  </div>
                  <div className="fsba">
                    <h4>Vo2</h4>
                    <input
                      type="number"
                      placeholder="0"
                      value={vo2}
                      onChange={(e) => setVo2(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <button className="formButton" type="submit">
              Save
            </button>
          </form>
          {showModal && (
            <MemberAddEditDeleteModal
              navigate={() => navigate("/members")}
              handleClose={() => setShowModal(false)}
            >
              <h1>The member has been editted!</h1>
            </MemberAddEditDeleteModal>
          )}
        </div>
      </div>
    </div>
  );
};

export default MembersEdit;
