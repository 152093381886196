import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Conversation from "./../../components/Contact/Conversation/Conversation";
import Message from "./../../components/Contact/Message/Message";
import { AccountContext } from "../../routes/AccountContext";
import { filterPosts } from "../../components/SearchBar/SearchBar";
import { io } from "socket.io-client";

import "./contact.css";
import Sidebar from "../../components/Menu/Sidebar";

const Contact = () => {
  const { user } = useContext(AccountContext);
  const { search } = window.location;
  const query = new URLSearchParams(search).get("s");
  const [searchQuery, setSearchQuery] = useState(query || "");
  const [conversations, setConversations] = useState([]);
  const [members, setMembers] = useState([]);
  const filteredMembers = filterPosts(members, searchQuery, "member");

  const theUser = user.user;

  const [currentChat, setCurrentChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const socket = useRef();
  const scrollRef = useRef();

  useEffect(() => {
    socket.current = io(process.env.REACT_APP_SOCKET_IO);
    socket.current.on("getMessage", (data) => {
      setArrivalMessage({
        sender: data.senderId,
        text: data.text,
        createdAt: Date.now(),
      });
    });
  }, []);

  useEffect(() => {
    arrivalMessage &&
      currentChat?.members.includes(arrivalMessage.sender) &&
      setMessages((prev) => [...prev, arrivalMessage]);
  }, [arrivalMessage, currentChat]);

  useEffect(() => {
    socket.current.emit("addUser", theUser);
  }, [theUser]);

  useEffect(() => {
    const getConversations = async () => {
      try {
        const responseCon = await fetch(
          `${process.env.REACT_APP_API_MAIN_URL}chats/${theUser}`,
          {
            method: "GET",
            headers: {
              authorization: `Bearer ${user?.token}`,
            },
          }
        );
        const responseMem = await fetch(
          `${process.env.REACT_APP_API_MAIN_URL}members/myMembers/${theUser}`,
          {
            method: "GET",
            headers: {
              authorization: `Bearer ${user?.token}`,
            },
          }
        );
        const jsonDataCon = await responseCon.json();
        const jsonDataMem = await responseMem.json();
        setConversations(jsonDataCon);
        setMembers(jsonDataMem);
      } catch (err) {
        console.log(err.message);
      }
    };

    getConversations();
  }, [theUser]);

  useEffect(() => {
    const getMessages = async () => {
      try {
        if (!currentChat.id) {
          return;
        }
        const response = await fetch(
          `${process.env.REACT_APP_API_MAIN_URL}chats/messages/${currentChat?.id}`,
          {
            method: "GET",
            headers: {
              authorization: `Bearer ${user?.token}`,
            },
          }
        );
        const jsonData = await response.json();
        setMessages(jsonData);
      } catch (err) {
        console.log(err.message);
      }
    };

    currentChat && getMessages();
  }, [currentChat]);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const receiverId = currentChat.members.find((member) => member !== theUser);
    try {
      socket.current.emit("sendMessage", {
        senderId: theUser,
        receiverId,
        text: newMessage,
      });
    } catch (error) {
      console.log(error);
    }

    /*try {
      if (!currentChat.id) {
        await fetch(`${process.env.REACT_APP_API_MAIN_URL}chats`, {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${user?.token}`,
          },
          body: JSON.stringify({
            senderId: theUser,
            receiverId: receiverId,
          }),
        })
          .then((response) => response.json())
          .then((json) => {
            currentChat.id = json.conversationId;
          });
      }

      fetch(`${process.env.REACT_APP_API_MAIN_URL}chats/messages`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${user?.token}`,
        },
        body: JSON.stringify({
          sender: theUser,
          text: newMessage,
          conversation_id: currentChat.id,
        }),
      }).then((response) => {
        response.json();
        setMessages([
          ...messages,
          {
            sender: theUser,
            text: newMessage,
            conversation_id: currentChat.id,
          },
        ]);
        setNewMessage("");
      });
    } catch (err) {
      console.log(err.message);
    }*/
  };

  return (
    <div className="layout">
      <Sidebar selected="Contact" />
      <div className="layout__content">
        <div className="layout__content-main">
          <div className="chat">
            <div className="chatMenu">
              <div className="chatMenuWrapper">
                <input
                  value={searchQuery}
                  onInput={(e) => setSearchQuery(e.target.value)}
                  type="text"
                  id="header-search"
                  placeholder="Search for a member"
                  className="chatMenuInput"
                  name="s"
                />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                  }}
                >
                  <div>
                    {searchQuery === "" && conversations.length > 0 && (
                      <h3>Conversations</h3>
                    )}
                    {searchQuery === "" &&
                      conversations.length > 0 &&
                      conversations?.map((conver) => (
                        <div
                          onClick={() => {
                            setMessages([]);
                            setCurrentChat(conver);
                          }}
                        >
                          <Conversation key={conver.id} conversation={conver} />
                        </div>
                      ))}
                  </div>
                  <div>
                    <h3>Members</h3>

                    {filteredMembers?.map(
                      (member) =>
                        member.userid !== theUser && (
                          <div
                            onClick={() => {
                              let found = false;
                              let theConver = null;
                              conversations?.map((conver) =>
                                conver.members.find((m) => m === member.userid)
                                  ? (found = true) && (theConver = conver)
                                  : null
                              );
                              found ? newFunction() : newFunction_1();

                              function newFunction_1() {
                                setMessages([]);
                                return setCurrentChat({
                                  members: [theUser, member.userid],
                                });
                              }

                              function newFunction() {
                                setMessages([]);
                                return setCurrentChat(theConver);
                              }
                            }}
                          >
                            <Conversation
                              key={member.userid}
                              conversation={{
                                members: [theUser, member.userid],
                              }}
                            />
                          </div>
                        )
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="chatBox">
              <div className="chatBoxWrapper">
                {currentChat ? (
                  <Fragment>
                    <div className="chatBoxTop">
                      {messages &&
                        messages.map((mess) => (
                          <div ref={scrollRef}>
                            <Message
                              key={mess.id}
                              message={mess}
                              own={mess.sender === theUser}
                              profilePic={
                                mess.sender === theUser
                                  ? user.userProfilePicture
                                  : members.find(
                                      (member) => member.userid === mess.sender
                                    ).userProfilePicture
                              }
                            />
                          </div>
                        ))}
                    </div>
                    <div className="chatBoxBottom">
                      <textarea
                        className="chatMessageInput"
                        placeholder="Write something..."
                        onChange={(e) => setNewMessage(e.target.value)}
                        value={newMessage}
                      />
                      <button
                        className="chatSubmitButton"
                        onClick={handleSubmit}
                      >
                        Send
                      </button>
                    </div>
                  </Fragment>
                ) : (
                  <span className="noConversationText">
                    Open a conversation to start a chat.
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
