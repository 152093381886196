import React, { useContext, useEffect, useState } from "react";
import { AccountContext } from "../../../routes/AccountContext";
import { MdAccountCircle } from "react-icons/md";
import "./conversation.css";

const Conversation = ({ conversation }) => {
  const { user } = useContext(AccountContext);
  const [member, setUser] = useState(null);

  useEffect(() => {
    const getUser = async (memberId) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_MAIN_URL}members/member/${memberId}`,
          {
            method: "GET",
            headers: {
              authorization: `Bearer ${user?.token}`,
            },
          }
        );
        const jsonData = await response.json();
        setUser(jsonData.member);
      } catch (err) {
        console.log(err.message);
      }
    };

    const memberId = conversation.members.find((m) => m !== user.user);
    getUser(memberId);
  }, [user, conversation]);

  return (
    <div className="conversation">
      {member?.profilepicture ? (
        <img className="conversationImg" src={member.profilepicture} alt="" />
      ) : (
        <MdAccountCircle className="conversationImg" />
      )}
      <span className="conversationName">
        {member && member.lastname + " " + member.firstname}
      </span>
    </div>
  );
};

export default Conversation;
