import { useNavigate } from "react-router";
import { useContext, useState } from "react";
import { AccountContext } from "../AccountContext";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { CgPassword } from "react-icons/cg";

import { MdOutlineAlternateEmail } from "react-icons/md";

import "./login.css";

const Login = () => {
  const { setUser } = useContext(AccountContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisable, setPasswordVisable] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  return (
    <div className="login">
      <div className="login_left">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h2>NXT REHAB</h2>
          <h4>YOUR ONLINE TRAINING PLANNER </h4>
        </div>
        <div></div>
      </div>
      <div className="login_right">
        <h1>Login</h1>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "5%",
          }}
        >
          <h2>Welcome back,</h2>
          <h3>Happy to see you again!</h3>
        </div>
        <form
  className="formLogin"
  onSubmit={(e) => {
    e.preventDefault();
    fetch(`${process.env.REACT_APP_API_AUTH_URL}login`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    })
      .then((response) => {
        if (response.statusText === "Too Many Requests") {
          setError("Too Many Requests, Please try again after 5 Min.");
          return null; // Return null to stop further execution
        }
        return response.json();
      })
      .then((json) => {
        if (!json) return; // Handle the case where json is null
        setUser({ ...json });
        if (json.errorMessage) {
          setError(json.errorMessage);
        } else if (json.loggedIn && !json.superAdmin) {
          navigate("/home");
        } else if (json.loggedIn && json.superAdmin) {
          navigate("/homeSuperAdmin");
        }
      })
      .catch((err) => {
        console.error(err); // Log the error for debugging purposes
        setError("An error occurred, please try again later.");
      });
  }}
>
          <div className="loginInput">
            <MdOutlineAlternateEmail style={{ width: "10%" }} />
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <div style={{ width: "10%" }}></div>
          </div>
          <div className="loginInput">
            <CgPassword style={{ width: "10%" }} />
            <input
              type={!passwordVisable ? "password" : "text"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            {passwordVisable ? (
              <FaEyeSlash
                style={{ width: "10%", cursor: "pointer" }}
                onClick={() => setPasswordVisable(false)}
              />
            ) : (
              <FaEye
                style={{ width: "10%", cursor: "pointer" }}
                onClick={() => setPasswordVisable(true)}
              />
            )}
          </div>
          <span className="loginError">{error}</span>
          <button type="submit">Sign In</button>
          <div className="passwordReset">
            <span>Did you forget your password? </span>
            <span>Reset your password here</span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
