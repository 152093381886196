import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HEADER_COMPANY from "../../components/Members/Details/Header_Company";
import Sidebar from "../../components/Menu/Sidebar";
import { SearchBar, filterPosts } from "../../components/SearchBar/SearchBar";
import { AccountContext } from "../../routes/AccountContext";
import "./../../components/SearchBar/SearchBar.css";
import {
  MainCategory,
} from "../../assets/constants";

const Exercises = () => {
  const [exercises, setExercises] = useState([]);
  const [info, setInfo] = useState([]);
  const { user } = useContext(AccountContext);
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedMainTarget, setSelectedMainTarget] = useState("");
  const [selectedSubTargets, setSelectedSubTargets] = useState([]);

  // Wait until all the exercises are loaded
  useEffect(() => {
    // Get all the exercises
    const getExercises = async () => {
      try {
        const userId = user.user;
        const response = await fetch(
          `${process.env.REACT_APP_API_MAIN_URL}exercises/${userId}`,
          {
            method: "GET",
            headers: {
              authorization: `Bearer ${user?.token}`,
            },
          }
        );
        const jsonData = await response.json();
        setExercises(jsonData);
      } catch (err) {
        console.log(err.message);
      }
    };

    const getInfo = async () => {
      try {
        const userId = user.id;
        const response = await fetch(
          `${process.env.REACT_APP_API_MAIN_URL}about/${userId}`,
          {
            method: "GET",
            headers: {
              authorization: `Bearer ${user?.token}`,
            },
          }
        );
        const jsonData = await response.json();
        setInfo(jsonData);
      } catch (err) {
        console.log(err.message);
      }
    };

    getExercises();
    getInfo();
  }, [user]);

  return (
    <div className="layout">
      <Sidebar selected="Exercises" />
      <div className="layout__content">
        <div className="layout__content-main">
          <HEADER_COMPANY
            title={info.company?.name}
            subTitle={"Admin: " + user.userName}
            info={"Exercises: " + exercises.length}
          />
          <div>
          <SearchBar
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              placeholder="Search by name"
              btnName="Add exercise"
              btnOnClick={() => navigate("/exercises/add")}
              mainTargets={MainCategory}
              selectedMainTarget={selectedMainTarget}
              setSelectedMainTarget={setSelectedMainTarget}
              selectedSubTargets={selectedSubTargets}
              setSelectedSubTargets={setSelectedSubTargets}
            />
            <div className="tableWrapper">
              <div className="table_overflow">
                <table>
                  <tbody>
                    <tr>
                      <th>Name</th>
                      <th>Targets</th>
                    </tr>

                    {filterPosts(
                      exercises,
                      searchQuery,
                      "exercise",
                      selectedSubTargets
                    ).map((post, i) => (
                      <tr
                        key={post.id}
                        onClick={() => {
                          const exerciseId = post.id;
                          navigate(`/exercises/${exerciseId}`);
                        }}
                      >
                        <td>{post.name}</td>
                        <td>
                          {post.targets &&
                            post.targets.map((target, i) => {
                              if (i === 0) {
                                return target;
                              } else if (i < 4) {
                                return ", " + target;
                              } else {
                                return "";
                              }
                            })}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Exercises;