import React, { useContext, useState } from 'react';
import ADD_DETAIL_HEADER from '../../components/Add_Detail_Header';
import Sidebar from '../../components/Menu/Sidebar';
import '../../assets/css/form.css';
import MemberAddEditDeleteModal from '../../components/Members/MemberAddEditDeleteModal';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../../routes/AccountContext';

function MembersAdd() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [gender, setGender] = useState('Male');
  const [length, setLength] = useState(0);
  const [sport, setSport] = useState('');
  const [weight, setWeight] = useState(0);
  const [vo2, setVo2] = useState('');
  const [injury, setInjury] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [userCode, setUserCode] = useState('');
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { user } = useContext(AccountContext);

  const createUser = async () => {
    setLoading(true);

    try {
      const userId = user.user;
      fetch(`${process.env.REACT_APP_API_AUTH_URL}signup`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${user?.token}`,
        },
        body: JSON.stringify({
          userId,
          firstName,
          lastName,
          email,
          phone,
          gender,
          length,
          sport,
          weight,
          vo2,
          injury,
        }),
      })
        .then((response) => response.json())
        .then((json) => {
          setUserCode(json.code);
          if (json.error) setHasError(true);
          setShowModal(true);
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <div className='layout'>
      <Sidebar selected='Members' />
      <div className='layout__content'>
        <div className='layout__content-main'>
          <ADD_DETAIL_HEADER title={"Let's create a new Member"} />

          <form
            onSubmit={(e) => {
              createUser();
              e.preventDefault();
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ width: '48%' }}>
                <h4>First name</h4>
                <input
                  type='text'
                  placeholder='First Name'
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </div>
              <div style={{ width: '48%' }}>
                <h4>Last name</h4>
                <input
                  type='text'
                  placeholder='Last Name'
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </div>
            </div>
            <div>
              <h4>Email</h4>
              <input
                type='email'
                placeholder='Email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div>
              <h4>Phone</h4>
              <input
                type='tel'
                placeholder='Phone'
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ width: '30%' }}>
                <h4>Gender</h4>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '25',
                  }}
                >
                  <input
                    className='radio'
                    type='radio'
                    style={{ width: '50%', marginBottom: '0' }}
                    checked={gender === 'Male'}
                    value='Male'
                    onChange={(e) => {
                      setGender(e.target.value);
                    }}
                  />
                  <label>Male</label>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '25',
                  }}
                >
                  <input
                    className='radio'
                    type='radio'
                    style={{ width: '50%', marginBottom: '0' }}
                    checked={gender === 'Female'}
                    value='Female'
                    onChange={(e) => {
                      setGender(e.target.value);
                    }}
                  />
                  <label style={{ marginBottom: '0' }}>Female</label>
                </div>
              </div>
              <div style={{ width: '60%' }}>
                <h4>Optional</h4>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '25',
                  }}
                >
                  <div className='fsba'>
                    <h4>Length</h4>
                    <input
                      type='number'
                      placeholder='0 cm'
                      value={length}
                      onChange={(e) => setLength(e.target.value)}
                    />
                  </div>
                  <div className='fsba'>
                    <h4>Weigth</h4>
                    <input
                      type='number'
                      placeholder='0 kilo'
                      value={weight}
                      onChange={(e) => setWeight(e.target.value)}
                    />
                  </div>
                </div>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div className='fsba'>
                    <h4 style={{ marginBottom: '25px' }}>Sport</h4>
                    <input
                      type='text'
                      placeholder='Sport'
                      value={sport}
                      onChange={(e) => setSport(e.target.value)}
                    />
                  </div>
                  <div className='fsba'>
                    <h4>Vo2</h4>
                    <input
                      type='number'
                      placeholder='0'
                      value={vo2}
                      onChange={(e) => setVo2(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h4>Injury</h4>
              <input
                type='text'
                placeholder='Injury'
                value={injury}
                onChange={(e) => setInjury(e.target.value)}
              />
            </div>
            <button type='submit'>Save {loading && <p>LOADING...</p>}</button>
          </form>
        </div>
      </div>
      {showModal && (
        <MemberAddEditDeleteModal
          navigate={() => navigate('/members')}
          handleClose={() => setShowModal(false)}
        >
          <h1>A new member has been created!</h1>
          <p>user code: {userCode}</p>
          {hasError ? (
            <p>
              The email could not be send to your member, please check if your
              email is verified with the administrator.
            </p>
          ) : (
            <p>An email with the code has been sent to the member</p>
          )}
        </MemberAddEditDeleteModal>
      )}
    </div>
  );
}

export default MembersAdd;
