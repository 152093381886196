import React, { useEffect, useState, useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import Sidebar from "../../../components/Menu/Sidebar";
import WEEKPLAN_HEADER from "../../../components/trainingsProgram/Headers/Weekplan_Header";
import { AccountContext } from "../../../routes/AccountContext";

function WeekPlanDetails() {
  const [weekPlan, setWeekPlan] = useState([]);
  const [days, setDays] = useState(1);
  const [day, setDay] = useState(1);
  const [remarkRead, setRemarkRead] = useState([]);
  const { user } = useContext(AccountContext);
  let params = useParams();
  const { state } = useLocation();

  const getDays = (data) => {
    let max = 1;
    data.map((post) => {
      post.day + 1 > max && (max = post.day + 1) && setDays(post.day + 1);
      return;
    });
    setRemarkRead(new Array(max));

    data.map((post) => {
      let data = remarkRead;
      data[post.day] = {
        dayplanId: post.dayplan_id,
        comment: post.comments || "",
        commentread: post.commentread || false,
      };
      setRemarkRead(data);
      return;
    });
  };

  useEffect(() => {
    const getWeekPlan = async () => {
      try {
        const route = params.weekPlanId;
        const response = await fetch(
          `${process.env.REACT_APP_API_MAIN_URL}weekPlans/${route}`,
          {
            method: "GET",
            headers: {
              authorization: `Bearer ${user?.token}`,
            },
          }
        );
        const jsonData = await response.json();
        setWeekPlan(jsonData);
        getDays(jsonData);
      } catch (err) {
        console.log(err.message);
      }
    };

    getWeekPlan();
  }, []);

  const updateRead = async (dayplanId) => {
    try {
      fetch(
        `${process.env.REACT_APP_API_MAIN_URL}dayplans/remarksread/${dayplanId}`,
        {
          method: "put",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${user.token}`,
          },
          //body: JSON.stringify({}),
        }
      ).then((response) => {
        response.status != 200 && alert("There went something wrong");
      });
    } catch (err) {
      alert("There went something wrong");
      console.log(err.message);
    }
  };

  return (
    <div className="layout">
      <Sidebar selected={state?.forSale ? "Training programs" : "Members"} />
      <div className="layout__content">
        <div className="layout__content-main">
          <WEEKPLAN_HEADER
            title={state.title}
            subTitle={state.subTitle}
            underSubTitle={state.underSubTitle}
          />
          <div>
            <div style={{ display: "flex" }}>
              <h2 style={{ marginRight: "5px" }}>Day:</h2>
              <h2
                onClick={() => {
                  day > 1 && setDay(day - 1);
                }}
                style={day === 1 ? { display: "none" } : null}
              >
                {"<"}
              </h2>
              <h2>{day}</h2>
              <h2
                onClick={() => {
                  day < days && setDay(day + 1);
                }}
                style={day === days ? { display: "none" } : null}
              >
                {">"}
              </h2>
            </div>
            <h2>{weekPlan.name}</h2>
            {remarkRead[day - 1]?.comment && (
              <div style={{ paddingTop: 50, paddingBottom: 15 }}>
                <h4 style={{ paddingBottom: 5 }}>Members comment:</h4>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    justifyContent: "flex-start",
                  }}
                >
                  <input
                    key={remarkRead[day - 1].dayplanId}
                    type="checkbox"
                    value={remarkRead[day - 1].commentread}
                    disabled={remarkRead[day - 1].commentread}
                    checked={remarkRead[day - 1].commentread ? true : null}
                    style={{ marginRight: 10 }}
                    onChange={() => {
                      remarkRead[day - 1].commentread = true;
                      setRemarkRead(remarkRead);
                      updateRead(remarkRead[day - 1].dayplanId);
                    }}
                  />
                  <h5>{remarkRead[day - 1].comment}</h5>
                </div>
              </div>
            )}
            <div className="tableWrapper">
              <div className="table_overflow">
                <table>
                  <tbody>
                    <tr>
                      <th>Completed</th>
                      <th>Exersise name</th>
                      <th>Reps</th>
                      <th>Weight</th>
                    </tr>

                    {weekPlan.map(
                      (post, i) =>
                        post.day === day - 1 &&
                        ((!post.name && (
                          <tr key={post.id}>
                            <td colSpan="6">
                              <div className="tableWrapper">
                                <table>
                                  <tbody>
                                    <tr>
                                      <th colSpan={2}>
                                        {post.typetrainingname != ""
                                          ? `${post.typetraining}:${post.typetrainingname}`
                                          : post.typetraining}
                                      </th>
                                      <th></th>
                                      <th colSpan={1}>
                                        Rounds:
                                        {post.rounds || 1}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th>Completed</th>
                                      <th>Exersise name</th>
                                      <th>Reps</th>
                                      <th>Weight</th>
                                    </tr>
                                    {post.replay.map((post1, i) => (
                                      <tr key={post1.id}>
                                        <td>{post1.finished ? "yes" : "no"}</td>
                                        <td>{post1.name}</td>
                                        <td>{post1.reps || "-"}</td>
                                        <td>{post1.weight || "-"}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </td>
                          </tr>
                        )) ||
                          (post.name && (
                            <tr key={post.id}>
                              <td>{post.finished ? "yes" : "no"}</td>
                              <td>{post.name}</td>
                              <td>{post.reps || "-"}</td>
                              <td>{post.weight || "-"}</td>
                            </tr>
                          )))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WeekPlanDetails;
