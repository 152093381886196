import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import sidebar_items from "../../assets/sidebar_routes";
import sidebarSuperAdmin_items from "../../assets/sidebarSuperAdmin_routes";
import SidebarItem from "./SidebarItem.js";
import { AccountContext } from "../../routes/AccountContext";
import { MdAccountCircle } from "react-icons/md";

const Sidebar = ({ selected }) => {
  const { user } = useContext(AccountContext);
  const [companyLogo, setCompanyLogo] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;

    const getCompanyLogo = async () => {
      try {
        const userId = user?.user;
        const response = await fetch(
          `${process.env.REACT_APP_API_MAIN_URL}homeSuperAdmin/companyLogo/${userId}`,
          {
            method: "GET",
            headers: {
              authorization: `Bearer ${user?.token}`,
            },
          }
        );
        const jsonData = await response.json();

        if (isMounted) {
          setCompanyLogo(jsonData);
        }
      } catch (err) {
        console.log(err.message);
      }
    };

    if (!user.superAdmin) {
      getCompanyLogo();
    }

    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, [user?.user, user?.token, user?.superAdmin]);

  return (
    <div className="sidebar_menu">
      <div className="sidebar_logo">
        {user.superAdmin ? (
          <img
            src={require("../../assets/logo192.png")}
            alt="Yellow Jersey"
            style={{ width: "300px" }}
          />
        ) : companyLogo?.logo ? (
          <img
            src={companyLogo.logo}
            alt="Logo"
            style={{ width: "200px", borderRadius: "100%" }}
          />
        ) : (
          <MdAccountCircle size={"150px"} />
        )}
      </div>
      <div>
        {(user.superAdmin ? sidebarSuperAdmin_items : sidebar_items).map(
          (item, index) => (
            <Link to={item.route} key={index}>
              <SidebarItem
                title={item.display_name}
                icon={item.icon}
                active={item.display_name === selected}
              />
            </Link>
          )
        )}
      </div>
      <div className="sidebar_logout">
        <span
          className="sidebar-text"
          onClick={() => {
            try {
              fetch(`${process.env.REACT_APP_API_AUTH_URL}logout`, {
                method: "POST",
                credentials: "include",
              }).then((response) => navigate("/login"));
            } catch (err) {
              console.log(err.message);
            }
          }}
        >
          Logout
        </span>
      </div>
    </div>
  );
};

export default Sidebar;
