import React, { useState, useEffect } from "react";
import "./WeekPrograms.css";
import DayProgram from "../DayPrograms/DayPrograms";
import { IoAddCircle } from "react-icons/io5";
import { MdDelete } from "react-icons/md";

const WeekProgram = ({ weekData, week, updateTrainingData, handleDeleteDay, handleDeleteWeek}) => {
  const [amountDays, setAmountDays] = useState(0);

  useEffect(() => {
    setAmountDays(weekData.training.length);
  }, [weekData.training.length]);

  const handleAddDay = () => {
    setAmountDays((prevAmountDays) => prevAmountDays + 1);
  };

  return (
    <div id="week-container">
      <h2 id="title">Week {week + 1} <button title="Remove" onClick={() => handleDeleteWeek(week)}><MdDelete className="delete-icon"></MdDelete></button></h2>
      <div className="week-flexbox">
        {[...Array(amountDays)].map((_, index) => (
          <DayProgram
            key={index}
            week={week}
            day={index}
            dayData={weekData.training[index] || {}}
            updateTrainingData={updateTrainingData}
            handleDeleteDay={handleDeleteDay}
          />
        ))}
        {amountDays < 7 && (
          <button className="add-day" onClick={handleAddDay} title="Add a new day">
            <IoAddCircle className="icon-add" />
          </button>
        )}
      </div>
    </div>
  );
};

export default WeekProgram;
