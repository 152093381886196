import React from "react";
import { IoArrowBackOutline, IoCloseCircle } from "react-icons/io5";
import { BsPencilFill } from "react-icons/bs";

import { useNavigate } from "react-router-dom";

const Weekplan_Header = ({
  title,
  subTitle,
  underSubTitle,
  editOnClick,
  closeOnClick,
}) => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        borderBottomStyle: "solid",
        borderWidth: 2,
        marginBottom: "25px",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          {!closeOnClick && (
            <IoArrowBackOutline onClick={() => navigate(-1)} size="30px" />
          )}
        </div>
        <div>
          <h1>{title}</h1>
        </div>
        <div>
          {editOnClick && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <h4 onClick={() => editOnClick()}>Edit</h4>
              <BsPencilFill style={{ marginLeft: "5px" }} />
            </div>
          )}
          {closeOnClick && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <IoCloseCircle size="25px" onClick={() => closeOnClick()} />
            </div>
          )}
        </div>
      </div>
      <div>
        <h3>{subTitle}</h3>
      </div>
      <div>
        <h5>{underSubTitle}</h5>
      </div>
    </div>
  );
};

export default Weekplan_Header;
