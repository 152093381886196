//module
import confirmModal from "../../components/Confirm/ConfirmModal";
import HEADER_MEMBER from "../../components/Members/Details/Header_Member";
import MemberAddEditDeleteModal from "../../components/Members/MemberAddEditDeleteModal";
import Sidebar from "../../components/Menu/Sidebar";
import { AccountContext } from "../../routes/AccountContext";
import PDFDROPZONE from "../../components/PdfDropzone/PdfDropzone";

//lib
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

//icons
import { MdDelete } from "react-icons/md";
import { BsPencilFill } from "react-icons/bs";

//css
import "./Members.css";

function MemberDetails() {
  const { state } = useLocation();
  const [member, setMember] = useState({});
  const [trainingPrograms, setTrainingPrograms] = useState([]);
  const [programsForSale, setProgramsForSale] = useState([]);
  const [toDelete, setToDelete] = useState(null);
  let params = useParams();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("schedules");
  const [injuryInput, setInjuryInput] = useState("");
  const [newPass, setnewPass] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showNewMemberCode, setShowNewMemberCode] = useState(false);
  const [updateInjuryModal, setUpdateInjuryModal] = useState(false);
  const [passwordResetModal, setPasswordResetModal] = useState(false);
  const [deleteShedule, setDeleteShedule] = useState(false);
  const [deleteMemberModal, setDeleteMemberModal] = useState(false);
  const [listTrProgramsModal, setListTrProgramsModal] = useState(false);
  const [addTrProgramModal, setAddTrProgramModal] = useState(false);
  const { user } = useContext(AccountContext);
  const [uploadedPdfs, setUploadedPdfs] = useState([]);

  const getTrainingPlan = async (trainingPlanId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_MAIN_URL}trainingPrograms/fullTrainingProgram/${trainingPlanId}`,
        {
          method: "GET",
          headers: {
            authorization: `Bearer ${user?.token}`,
          },
        }
      );
      const jsonData = await response.json();
      navigate("/trainingProgram/add", {
        state: { userId: member.userid, fixedData: jsonData },
      });
    } catch (err) {
      console.log(err.message);
    }
  };

  const editTrainingPlan = async (trainingPlanId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_MAIN_URL}trainingPrograms/fullTrainingProgram/${trainingPlanId}`,
        {
          method: "GET",
          headers: {
            authorization: `Bearer ${user?.token}`,
          },
        }
      );
      const jsonData = await response.json();

      navigate("/trainingProgram/add", {
        state: {
          userId: member.userid,
          fixedData: jsonData,
          planId: trainingPlanId,
        },
      });
    } catch (err) {
      console.log(err.message);
    }
  };

  const getTrainingProgramforSale = async () => {
    try {
      const route = user.user;
      const response = await fetch(
        `${process.env.REACT_APP_API_MAIN_URL}trainingPrograms/trainingProgramsForSale/${route}`,
        {
          method: "GET",
          headers: {
            authorization: `Bearer ${user?.token}`,
          },
        }
      );
      const jsonData = await response.json();
      setProgramsForSale(jsonData);
    } catch (err) {
      console.log(err.message);
    }
  };

  const deleteMember = async () => {
    try {
      await fetch(
        `${process.env.REACT_APP_API_MAIN_URL}members/${member.userid}`,
        {
          method: "DELETE",
          credentials: "include",
          headers: {
            authorization: `Bearer ${user?.token}`,
          },
        }
      ).then((response) => {
        if (response.status === 200) {
          setShowModal(true);
        } else {
          alert("There went something wrong");
        }
      });
    } catch (err) {
      alert("something went wrong");
      console.log(err.message);
    }
  };

  const updateMemberInjury = async () => {
    try {
      const route = params.userId;

      await fetch(
        `${process.env.REACT_APP_API_MAIN_URL}members/memberInjuries/${route}`,
        {
          method: "PUT",
          credentials: "include",
          headers: {
            "Content-Type": "Application/json",
            authorization: `Bearer ${user?.token}`,
          },
          body: JSON.stringify({ injury: injuryInput }),
        }
      ).then((response) => {
        if (response.status === 200) {
          setUpdateInjuryModal(false);
        } else {
          alert("There went something wrong");
        }
      });
    } catch (err) {
      alert("something went wrong");
      console.log(err.message);
    }
  };

  const getTrainingPrograms = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_MAIN_URL}trainingPrograms/${id}`,
        {
          method: "GET",
          headers: {
            authorization: `Bearer ${user?.token}`,
          },
        }
      );
      const jsonData = await response.json();
      setTrainingPrograms(jsonData);
    } catch (err) {
      console.log(err.message);
    }
  };

  const memberPasswordReset = async () => {
    try {
      const { userId } = params;
      await fetch(`${process.env.REACT_APP_API_AUTH_URL}resetPasswordMember`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${user?.token}`,
        },
        body: JSON.stringify({
          userid: userId,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setnewPass(data.code);
          setShowNewMemberCode(true);
        });
    } catch (err) {
      console.log(err.message);
    }
  };

  const deleteTraining = async () => {
    try {
      await fetch(
        `${process.env.REACT_APP_API_MAIN_URL}trainingPrograms/${toDelete}`,
        {
          method: "DELETE",
          credentials: "include",
          headers: { authorization: `Bearer ${user?.token}` },
        }
      ).then((response) => {
        if (response.status !== 200) {
          alert("There went something wrong");
        }
        deletePdfs();
      });
    } catch (err) {
      console.log(err.message);
    }
  };

  const deletePdfs = async () => {
    try {
      await fetch(
        `${process.env.REACT_APP_API_MAIN_URL}pdfs/trainingprogram/${toDelete}`,
        {
          method: "DELETE",
          credentials: "include",
          headers: { authorization: `Bearer ${user?.token}` },
        }
      ).then((response) => {
        if (response.status !== 200) {
          alert("There went something wrong");
        }
      });
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    const getMember = async () => {
      try {
        const route = params.userId;
        const response = await fetch(
          `${process.env.REACT_APP_API_MAIN_URL}members/member/${route}`,
          {
            method: "GET",
            headers: {
              authorization: `Bearer ${user?.token}`,
            },
          }
        );
        const jsonData = await response.json();

        setMember(jsonData.member);
        setInjuryInput(jsonData.member.injury.join(", "));
        getTrainingPrograms(jsonData.member.id);
        getTrainingProgramforSale(jsonData.member.id);
        fetchPdfs(jsonData.member.id);
      } catch (err) {
        console.log(err.message);
      }
    };

    getMember();
  }, []);

  useEffect(() => {}, [uploadedPdfs]);

  const [pdfs, setPdfs] = useState([]);
  const fetchPdfs = async (memberId) => {
    try {
      if (memberId) {
        const response = await fetch(
          `${process.env.REACT_APP_API_MAIN_URL}pdfprograms/${memberId}`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              authorization: `Bearer ${user?.token}`,
            },
          }
        );
        if (response.ok) {
          const pdfData = await response.json();
          setPdfs(pdfData);
        } else {
          console.error("Failed to fetch PDFs:", response.statusText);
        }
      }
    } catch (error) {
      console.error("Error fetching PDFs:", error);
    }
  };

  // ...
  const [pdfUploadStatus, setPdfUploadStatus] = useState(true);
  // ...

  const handlePdfUpload = (pdfFile) => {
    setUploadedPdfs(pdfFile);
  };

  const postPDFs = async () => {
    const formData = new FormData();
    uploadedPdfs.forEach((pdfFile) => {
      formData.append(`pdfFiles`, pdfFile);
    });
    formData.append("memberId", member.id); // Append the training program ID

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_MAIN_URL}pdfprograms`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            // You're sending a FormData object, so don't manually set 'Content-Type'
            // 'Content-Type': 'multipart/form-data',
            authorization: `Bearer ${user?.token}`,
          },
          body: formData, // Use the FormData object as the request body
        }
      );

      if (response.ok) {
        fetchPdfs(member.id);
        setUploadedPdfs([]);
        setPdfUploadStatus((prevStatus) => !prevStatus);
      } else {
        console.error("Failed to upload PDF:", response.statusText);
      }
    } catch (error) {
      console.error("Error uploading PDF:", error);
    }
  };

  const handleDeletePdf = async (filename) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_MAIN_URL}pdfprograms/${filename}`,
        {
          method: "DELETE",
          headers: {
            authorization: `Bearer ${user?.token}`,
          },
        }
      );

      if (response.ok) {
        setPdfs((prevPdfs) =>
          prevPdfs.filter((pdf) => pdf.filename !== filename)
        );
      } else {
        console.error("Failed to delete PDF:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting PDF:", error);
    }
  };

  return (
    <div className="layout">
      <Sidebar selected="Members" />
      <div className="layout__content">
        <div className="layout__content-main">
          <HEADER_MEMBER
            member={member}
            name={member.lastname + " " + member.firstname}
            sport={member.sport}
            injury={member.injury}
            add={() => setAddTrProgramModal(true)}
            reset={() => setPasswordResetModal(true)}
            remove={() => setDeleteMemberModal(true)}
            updateInjury={() => setUpdateInjuryModal(true)}
            editOnClick={() => navigate(`/members/edit/${member.userid}`)}
            picture={member.profilepicture}
          />
          <div>
            <div className="contentHeaders">
              <span
                className={selectedTab === "schedules" ? "spanActive" : ""}
                onClick={() => setSelectedTab("schedules")}
              >
                Schedules
              </span>
              <span
                className={selectedTab === "files" ? "spanActive" : ""}
                onClick={() => setSelectedTab("files")}
              >
                Files
              </span>
              <span
                className={selectedTab === "about" ? "spanActive" : ""}
                onClick={() => setSelectedTab("about")}
              >
                About
              </span>
              <div></div>
            </div>
            {selectedTab === "schedules" && (
              <div className="tableWrapper">
                <div className="table_overflow">
                  <table>
                    <tbody>
                      <tr>
                        <th>Name</th>
                        <th>Weeks</th>
                        <th>Start date</th>
                        <th>Comments</th>
                        <th>Edit/Delete</th>
                      </tr>

                      {trainingPrograms &&
                        trainingPrograms.length > 0 &&
                        trainingPrograms.map((post, i) => (
                          <tr key={post.id}>
                            <td
                              onClick={() => {
                                const scheduleId = post.id;
                                navigate(`/trainingProgram/${scheduleId}`, {
                                  state: { member },
                                });
                              }}
                            >
                              {post.name ? post.name : "/"}
                            </td>
                            <td
                              onClick={() => {
                                const scheduleId = post.id;
                                navigate(`/trainingProgram/${scheduleId}`, {
                                  state: { member },
                                });
                              }}
                            >
                              {post.weeks}
                            </td>
                            <td
                              onClick={() => {
                                const scheduleId = post.id;
                                navigate(`/trainingProgram/${scheduleId}`, {
                                  state: { member },
                                });
                              }}
                            >
                              {new Date(post.startdate).getDate()}-
                              {new Date(post.startdate).getMonth()}-
                              {new Date(post.startdate).getFullYear()}
                            </td>
                            <td
                              onClick={() => {
                                const scheduleId = post.id;
                                navigate(`/trainingProgram/${scheduleId}`, {
                                  state: { member },
                                });
                              }}
                            >
                              {post.comments}
                            </td>
                            <td>
                              {
                                <Fragment>
                                  <BsPencilFill
                                    className="space"
                                    onClick={() => {
                                      const trainingPlanId = post.id;
                                      editTrainingPlan(trainingPlanId);
                                    }}
                                  />
                                  /
                                  <MdDelete
                                    className="space"
                                    onClick={() => {
                                      setToDelete(post.id);
                                      setDeleteShedule(true);
                                    }}
                                  />
                                </Fragment>
                              }
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            {selectedTab === "files" && (
              <div style={{marginTop: "5%"}}>
                  <PDFDROPZONE key={pdfUploadStatus} onPdfUpload={handlePdfUpload} />
                <div>
                  <button
                    onClick={() => postPDFs()}
                    style={{
                      marginTop: "15px",
                      marginBottom: "15px",
                      height: "50px",
                      width: "200px",
                      color: "var(--main-color)",
                      backgroundColor: "var(--main-color-accent-light)",
                      boxShadow: "var(--box-shadow)",
                      borderRadius: "45px",
                      fontweight: "600",
                      display: "block",
                      textalign: "center",
                    }}
                  >
                    Upload
                  </button>
                  <h3>Uploaded PDFs:</h3>
                  <ul>
                    {pdfs.map((pdf, index) => (
                      <li
                        style={{
                          marginBottom: "10px",
                        }}
                        key={index}
                      >
                        <a
                          href={`${process.env.REACT_APP_API_MAIN_URL}pdfs/download-pdf/${pdf.filename}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {pdf.filename}
                        </a>
                        <button
                          onClick={() => handleDeletePdf(pdf.filename)}
                          style={{
                            marginLeft: "10px",
                            backgroundColor: "red",
                            color: "white",
                            border: "none",
                            padding: "5px 10px",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                        >
                          Delete
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}

            {selectedTab === "about" && (
              <div className="aboutMembers">
                <div>
                  <h2>Contact information</h2>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span>Email: {member.email}</span>
                      <span>Phone: {member.phone}</span>
                      <span>Gender: {member.gender}</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginRight: "25%",
                      }}
                    >
                      <span>Length: {member.length}</span>
                      <span>Weight: {member.weight}</span>
                      <span>Vo2 Max: {member.vo2max}</span>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "5%" }}>
                  <h2>Injury</h2>
                  <ul>
                    {member.injury ? (
                      member.injury.map((theInjury, i) => (
                        <li key={i}> {theInjury} </li>
                      ))
                    ) : (
                      <li>None</li>
                    )}
                  </ul>
                </div>
              </div>
            )}
          </div>
          {addTrProgramModal && (
            <div
              className="memberModal"
              onClick={() => setAddTrProgramModal(false)}
            >
              <div className="memberModal-content">
                <h2>
                  {programsForSale && programsForSale.length > 0
                    ? "Do you want to create a new schedule or do you want to load a fixed schedule"
                    : "No fixed schedules found, please create a new schedule."}
                </h2>
                {Array.isArray(programsForSale) &&
                  programsForSale.length > 0 && (
                    <button onClick={() => setListTrProgramsModal(true)}>
                      Fixed schedule
                    </button>
                  )}
                <button
                  onClick={() =>
                    navigate("/trainingProgram/add", {
                      state: { userId: member.userid },
                    })
                  }
                >
                  New schedule
                </button>
              </div>
            </div>
          )}
          {listTrProgramsModal && (
            <div
              className="memberModal"
              onClick={() => setListTrProgramsModal(false)}
            >
              <div className="memberModal-content">
                <h2>Select a fixed schedule</h2>
                <table>
                  <tbody>
                    <tr>
                      <th>Name</th>
                      <th>weeks</th>
                    </tr>
                    {programsForSale &&
                      programsForSale.length > 0 &&
                      programsForSale.map((post, i) => (
                        <tr
                          key={post.id}
                          onClick={() => {
                            const trainingPlanId = post.id;
                            getTrainingPlan(trainingPlanId);
                          }}
                        >
                          <td>{post.name}</td>
                          <td>{post.weeks}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {passwordResetModal && (
            <div
              className="memberModal"
              onClick={() => setPasswordResetModal(false)}
            >
              <div className="memberModal-content">
                <h2>Are you sure you want to reset your password?</h2>
                {/* do the put request, update email and redirect to user page */}
                <button onClick={() => memberPasswordReset()}>Yes</button>
                <button className="modalUpdateButton">No</button>
              </div>
            </div>
          )}
          {deleteShedule &&
            confirmModal({
              title: "Are you sure you want to delete this schedule?",
              handleYes: () => {
                deleteTraining();
                getTrainingPrograms(member.id);
              },
              setVisible: setDeleteShedule,
            })}

          {deleteMemberModal &&
            confirmModal({
              title: "Are you sure you want to delete this member?",
              handleYes: () => {
                deleteMember();
                navigate("/members");
              },
              setVisible: setDeleteMemberModal,
            })}

          {updateInjuryModal && (
            <div className="memberModal">
              <div className="memberModal-content">
                <h2>Add the injuries, seperated by a comma</h2>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    updateMemberInjury();
                    setMember({ ...member, injury: injuryInput.split(",") });
                  }}
                >
                  <input
                    type="text"
                    size="100"
                    value={injuryInput}
                    onChange={(e) => setInjuryInput(e.target.value)}
                  ></input>
                  <button className="modalUpdateButton">Update</button>
                </form>
                <button onClick={() => setUpdateInjuryModal(false)}>
                  close
                </button>
              </div>
            </div>
          )}

          {showNewMemberCode && (
            <div className="memberModal">
              <div className="memberModal-content">
                <h2 style={{ paddingBottom: 10 }}>
                  New member code: {newPass}
                </h2>
                <p style={{ marginBottom: 10 }}>
                  an email with the new code is sent to the member
                </p>
                <button onClick={() => setShowNewMemberCode(false)}>
                  close
                </button>
              </div>
            </div>
          )}
          {showModal && (
            <MemberAddEditDeleteModal
              navigate={() => navigate("/members")}
              handleClose={() => setShowModal(false)}
            >
              <h1>The member has been deleted</h1>
            </MemberAddEditDeleteModal>
          )}
        </div>
      </div>
    </div>
  );
}

export default MemberDetails;
