import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../components/Menu/Sidebar";
import "../../assets/css/layout.css";
import { useNavigate } from "react-router-dom";
import { AccountContext } from "../../routes/AccountContext";
import StatusCard from "../../components/cards/StatusCard/StatusCard";
import Chart from "react-apexcharts";
import "./Home.css";

function Home() {
  const navigate = useNavigate();
  const { user } = useContext(AccountContext);
  const [data, setData] = useState({
    lastWeeks: { done: [0, 0, 0, 0, 0], failed: [0, 0, 0, 0, 0] },
    coachingRequests: [],
  });
  const [loading, setLoading] = useState(true);

  const chartOptions = {
    options: {
      chart: {
        id: "basic-bar",
        width: "50%",
      },
      xaxis: {
        categories: [
          "5 weeks ago",
          "4 weeks ago",
          "3 weeks ago",
          "2 weeks ago",
          "Previous week",
        ],
      },
      yaxis: {
        categories: [10, 20, 30],
      },
    },
    series: [
      {
        name: "Finished",
        data: loading ? [] : data.lastWeeks.done, // Only use data when not loading
        color: "#010a10",
      },
      {
        name: "failed",
        data: loading ? [] : data.lastWeeks.failed, // Only use data when not loading
        color: "#c10206",
      },
    ],
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const coachRequestsResponse = await fetch(
          `${process.env.REACT_APP_API_MAIN_URL}coachRequests`, // Update the endpoint
          {
            method: "GET",
            headers: {
              authorization: `Bearer ${user?.token}`,
            },
          }
        );
        const coachRequestsData = await coachRequestsResponse.json();

        const userId = user.user;
        const response = await fetch(
          `${process.env.REACT_APP_API_MAIN_URL}home/charts/${userId}`,
          {
            method: "GET",
            headers: {
              authorization: `Bearer ${user?.token}`,
            },
          }
        );
        const jsonData = await response.json();
        setData({
          ...jsonData,
          coachingRequests: coachRequestsData,
        });
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false); // Set loading to false whether the request succeeds or fails
      }
    };

    getData();
  }, [user]);

  const handleApprove = async (requestId) => {
    try {
      const statusNumber = 0;

      const response = await fetch(
        `${process.env.REACT_APP_API_MAIN_URL}coachRequests/${requestId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${user?.token}`,
          },
          body: JSON.stringify({ statusNumber }),
        }
      );

      if (response.ok) {
        console.log("Successfully approved coaching request");
        const coachRequestsResponse = await fetch(
          `${process.env.REACT_APP_API_MAIN_URL}coachRequests`,
          {
            method: "GET",
            headers: {
              authorization: `Bearer ${user?.token}`,
            },
          }
        );
        const coachRequestsData = await coachRequestsResponse.json();
  
        setData((prevData) => ({
          ...prevData,
          coachingRequests: coachRequestsData,
        }));
      } else {
        console.error("Failed to approve coaching request");
      }
    } catch (error) {
      console.error("Error approving coaching request:", error);
    }
  };

  const handleDeny = async (requestId) => {
    try {
      const statusNumber = 1;

      const response = await fetch(
        `${process.env.REACT_APP_API_MAIN_URL}coachRequests/${requestId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${user?.token}`,
          },
          body: JSON.stringify({ statusNumber }),
        }
      );

      if (response.ok) {
        const coachRequestsResponse = await fetch(
          `${process.env.REACT_APP_API_MAIN_URL}coachRequests`,
          {
            method: "GET",
            headers: {
              authorization: `Bearer ${user?.token}`,
            },
          }
        );
        const coachRequestsData = await coachRequestsResponse.json();
  
        setData((prevData) => ({
          ...prevData,
          coachingRequests: coachRequestsData,
        }));
      } else {
        console.error("Failed to deny coaching request");
      }
    } catch (error) {
      console.error("Error denying coaching request:", error);
    }
  };

  return (
    <div className="layout">
      <Sidebar selected="Home" />
      <div className="layout__content">
        <div className="layout__content-main">
          <h1 className="home-title">{user?.company || ""}</h1>
          <div className="containerCharts">
            <div className="activeMembersChart">
              <StatusCard
                gauche={true}
                value={data.activeMembers}
                title={"Active members"}
                subtTile={"Last week"}
                style={{ maxHeight: 50 }}
              />
            </div>
            <div className="totalExerciseChart">
              <StatusCard
                gauche={false}
                value={data.exerciseTotal}
                title={"Exercises"}
                subtTile={"Total"}
              />
            </div>
            <div className="createdSchedulesChart">
              <StatusCard
                gauche={false}
                value={data.createdSchedules}
                title={"Created daily schedules"}
                subtTile={"This Year"}
              />
            </div>
            <div className="schedulesForSaleChart">
              <StatusCard
                gauche={false}
                value={data.schedulesForSale}
                title={"Schedules for sale"}
                subtTile={"Total"}
              />
            </div>

            <div className="barchartContainer">
              <h2 className="homeTables">Results last 5 weeks</h2>
              <Chart
                className="barChart"
                options={chartOptions.options}
                series={chartOptions.series}
                //width='100%'
                height="80%"
                type="bar"
              />
            </div>

            <div className="coachingRequests">
              <h2 className="homeTables">Coaching requests</h2>
              <div className="tableWrapper">
                <div className="table_overflow">
                  <table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Approve/Deny</th>
                      </tr>
                    </thead>

                    <tbody>
                      {data?.coachingRequests &&
                        data?.coachingRequests.map((coachingRequest, i) => (
                          <tr>
                            <td>
                              {coachingRequest.member.lastname}{" "}
                              {coachingRequest.member.firstname}
                            </td>
                            <td>{coachingRequest.member.email}</td>
                            <td>
                              <button
                                className="approveButton"
                                onClick={() =>
                                  handleApprove(coachingRequest.request.id)
                                }
                              >
                                Approve
                              </button>
                              /
                              <button
                                className="denyButton"
                                onClick={() =>
                                  handleDeny(coachingRequest.request.id)
                                }
                              >
                                Deny
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="latestUpdates">
              <h2 className="homeTables">Latest updates last week</h2>
              <div className="tableWrapper">
                <div className="table_overflow">
                  <table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Training plan status</th>
                        <th>Percentage done</th>
                      </tr>
                    </thead>

                    <tbody>
                      {data?.latestUpdates &&
                        data?.latestUpdates.map((post, i) => (
                          <tr
                            key={post.id}
                            onClick={() => {
                              const planId = post.id;
                              navigate(`/trainingProgram/${planId}`);
                            }}
                          >
                            <td>
                              {post.lastname} {post.firstname}
                            </td>
                            <td>
                              {post.percentagedone < 100
                                ? "In Progress"
                                : "Finished"}
                            </td>
                            <td>{post.percentagedone}%</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="unreadComments">
              <h2 className="homeTables">Unread comments</h2>
              <div className="tableWrapper">
                <div className="table_overflow">
                  <table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Comments</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.unReadComments &&
                        data?.unReadComments.map((post, i) => (
                          <tr
                            key={post.id}
                            onClick={() => {
                              const userId = post.userid;
                              navigate(`/members/${userId}`);
                            }}
                          >
                            <td>
                              {post.lastname} {post.firstname}
                            </td>
                            <td>{post.totalcomments}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
