import { useDrag } from "react-dnd";
import React, { useContext } from "react";

const SIDEBAR_ITEM = "sidebarItem";
const DAYSB = "day";
const CIRCUIT_AUXILARY = "circuit_auxilary";
const EXERCISE = "exercise";

const SideBarItem = ({ data }) => {
  const [{ opacity }, drag] = useDrag({
    item: data,
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  });
  return (
    <div className="dragDropSideBarItem" ref={drag} style={{ opacity }}>
      {data.exercise ? data.exercise.name : data.circuit_auxilary.name}
    </div>
  );
};

const SIDEBAR_ITEMS = async (user) => {
  let data = [];
  try {
    const response = await fetch(
      process.env.REACT_APP_API_MAIN_URL + "exercises/" + user.user,
      {
        method: "GET",
        headers: {
          authorization: `Bearer ${user.token}`,
        },
      }
    );
    const jsonData = await response.json();
    jsonData.forEach((element) => {
      data.push({
        id: data.length,
        type: SIDEBAR_ITEM,
        exercise: {
          name: element.name,
          dbId: element.id,
          type: "exercise",
        },
      });
    });
    data.push({
      id: data.length,
      type: SIDEBAR_ITEM,
      circuit_auxilary: {
        type: "circuit",
        name: "Circuit training",
        children: [],
      },
    });
    data.push({
      id: data.length,
      type: SIDEBAR_ITEM,
      circuit_auxilary: {
        type: "auxilary",
        name: "Auxilary training",
        children: [],
      },
    });
  } catch (err) {}

  return data;
};

export {
  SIDEBAR_ITEM,
  DAYSB,
  CIRCUIT_AUXILARY,
  EXERCISE,
  SIDEBAR_ITEMS,
  SideBarItem,
};
