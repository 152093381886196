import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ADD_DETAIL_HEADER from "../../components/Add_Detail_Header";
import Sidebar from "../../components/Menu/Sidebar";
import { AccountContext } from "../../routes/AccountContext";

import "./Exercises.css";

function ExerciseDetails() {
  const [exercise, setExercise] = useState([]);
  const [data, setData] = useState("");
  const [isActive1, setIsActive1] = useState(true);
  const [isActive2, setIsActive2] = useState(false);
  const [isActive3, setIsActive3] = useState(false);
  const { user } = useContext(AccountContext);

  let params = useParams();
  const navigate = useNavigate();

  const deleteExercise = async () => {
    const route = params.exerciseId;
    try {
      fetch(`${process.env.REACT_APP_API_MAIN_URL}exercises/${route}`, {
        method: "DELETE",
        credentials: "include",
        headers: {
          authorization: `Bearer ${user?.token}`,
        },
      }).then((response) => {
        if (response.status === 200) {
          alert("The exercise is deleted");
          navigate("/exercises");
        } else {
          alert("There went something wrong");
        }
      });
    } catch (err) {
      alert("There went something wrong");
      console.log(err.message);
    }
  };

  useEffect(() => {
    const getExercise = async () => {
      try {
        const route = params.exerciseId;
        const response = await fetch(
          `${process.env.REACT_APP_API_MAIN_URL}exercises/exercise/${route}`,
          {
            method: "GET",
            headers: {
              authorization: `Bearer ${user?.token}`,
            },
          }
        );
        const jsonData = await response.json();
        setExercise(jsonData);
        setData(jsonData.description);
      } catch (err) {
        console.log(err.message);
      }
    };

    getExercise();
  }, []);

  return (
    <div className="layout">
      <Sidebar selected="Exercises" />
      <div className="layout__content">
        <div className="layout__content-main">
          <ADD_DETAIL_HEADER
            title={exercise.name}
            editOnClick={() => navigate(`/exercises/edit/${exercise.id}`)}
          />
          <div>
            <div className="upperContainer">
              <div className="containerIframe">
                <div className="responsive-iframe">
                  <iframe
                    className="responsive-iframe"
                    crossOrigin="anonymous"
                    src={`https://www.youtube.com/embed/${exercise.videourl}`}
                  />
                </div>
              </div>
              <div className="upperLeftContainer">
                <div>
                  <h3>Targets</h3>
                  <ul>
                    {exercise.targets &&
                      exercise.targets.map((target, i) => {
                        return <li key={i}>{target}</li>;
                      })}
                  </ul>
                </div>
              </div>
            </div>
            <div className="lowerContainer">
              <div
                className={`option1 ${isActive1 ? "isActive" : null}`}
                onClick={() => {
                  setData(exercise.description);
                  setIsActive1(!isActive1);
                  setIsActive2(false);
                  setIsActive3(false);
                }}
              >
                <h3 className="optionHeader">Description</h3>
              </div>
              <div
                className={`option2 ${isActive2 ? "isActive" : null}`}
                onClick={() => {
                  setData(exercise.equipment);
                  setIsActive2(!isActive2);
                  setIsActive1(false);
                  setIsActive3(false);
                }}
              >
                <h3 className="optionHeader">Equipment</h3>
              </div>
              <div
                className={`option3 ${isActive3 ? "isActive" : null}`}
                onClick={() => {
                  setData(exercise.tips);
                  setIsActive3(!isActive3);
                  setIsActive1(false);
                  setIsActive2(false);
                }}
              >
                <h3 className="optionHeader">Tips</h3>
              </div>
              <div className="optionData">
                <ul>
                  {Array.isArray(data)
                    ? data.map((target, i) => {
                        return <li key={i}>{target}</li>;
                      })
                    : data}
                </ul>
              </div>
            </div>
            <button className="button-red" onClick={() => deleteExercise()}>
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExerciseDetails;
