import React, { useState, useEffect, useContext } from "react";
import "./DayPrograms.css";
import { IoAddCircle } from "react-icons/io5";
import { AccountContext } from "../../../routes/AccountContext";
import { SIDEBAR_ITEMS } from "../../trainingsProgram/newTrainingProgram/test/SideBarItem";
import Select from "react-select";
import { MdDelete } from "react-icons/md";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const DayProgram = ({
  week,
  day,
  dayData,
  updateTrainingData,
  handleDeleteDay,
}) => {
  const [dayName, setDayName] = useState(dayData.name || "");
  const [exerciseData, setExerciseData] = useState(dayData.children || []);
  const [sidebarItems, setSidebarItems] = useState([]);
  const { user } = useContext(AccountContext);

  useEffect(() => {
    updateTrainingData(week, day, dayName, exerciseData);
  }, [exerciseData, dayName]);

  useEffect(() => {
    let isMounted = true;

    SIDEBAR_ITEMS(user).then((items) => {
      if (isMounted) {
        setSidebarItems(items);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [user]);

  const addRow = (selectedType) => {
    setExerciseData((prevData) => {
      let newRow = {
        type: selectedType,
        name: "",
        extraTips: "",
        reps: "1",
        weight: "0",
      };

      if (selectedType === "circuit" || selectedType === "auxilary") {
        newRow = {
          rounds: "1",
          type: selectedType,
          typeTrainingName: "",
          typetraining: selectedType,
        };

        newRow.children = [
          {
            typeTraining: "normal",
            name: "",
            extraTips: "",
            reps: "1",
            weight: "0",
          },
        ];
      }

      return [...prevData, newRow];
    });
  };

  const deleteRow = (index) => {
    setExerciseData((prevData) => {
      const updatedData = [...prevData];
      updatedData.splice(index, 1);
      return updatedData;
    });
  };

  const deleteSpecialRow = (exerciseIndex, specialIndex) => {
    setExerciseData((prevData) => {
      const updatedData = [...prevData];
      const exerciseToUpdate = updatedData[exerciseIndex];

      if (exerciseToUpdate && exerciseToUpdate.children) {
        const updatedChildren = [
          ...exerciseToUpdate.children.slice(0, specialIndex),
          ...exerciseToUpdate.children.slice(specialIndex + 1),
        ];

        const updatedExercise = {
          ...exerciseToUpdate,
          children: updatedChildren,
        };

        updatedData[exerciseIndex] = updatedExercise;
      }

      return updatedData;
    });
  };

  const addSpecialRow = (exerciseIndex) => {
    setExerciseData((prevData) => {
      const updatedData = [...prevData];
      const exerciseToUpdate = updatedData[exerciseIndex];

      if (exerciseToUpdate && exerciseToUpdate.children) {
        const updatedChildren = [
          ...exerciseToUpdate.children,
          {
            typeTraining: "normal",
            name: "",
            extraTips: "",
            reps: "1",
            weight: "0",
          },
        ];

        const updatedExercise = {
          ...exerciseToUpdate,
          children: updatedChildren,
        };

        updatedData[exerciseIndex] = updatedExercise;
      }

      return updatedData;
    });
  };

  const onChangeMainExercise = (selectedOption, exerciseIndex) => {
    const selectedId = selectedOption ? selectedOption.value : "";
    const selected = sidebarItems.find(
      (item) => item.exercise && item.exercise.dbId === selectedId
    );

    if (selected) {
      setExerciseData((prevSelected) => [
        ...prevSelected.slice(0, exerciseIndex),
        {
          ...prevSelected[exerciseIndex],
          ...selected.exercise,
        },
        ...prevSelected.slice(exerciseIndex + 1),
      ]);
    }
  };

  const onChangeSpecialExercise = (
    selectedOption,
    exerciseIndex,
    specialIndex
  ) => {
    const selectedId = selectedOption ? selectedOption.value : "";
    const selected = sidebarItems.find(
      (item) => item.exercise && item.exercise.dbId === selectedId
    );

    if (selected) {
      setExerciseData((prevSelected) => [
        ...prevSelected.slice(0, exerciseIndex),
        {
          ...prevSelected[exerciseIndex],
          children: [
            ...prevSelected[exerciseIndex].children.slice(0, specialIndex),
            {
              ...prevSelected[exerciseIndex].children[specialIndex],
              ...selected.exercise,
            },
            ...prevSelected[exerciseIndex].children.slice(specialIndex + 1),
          ],
        },
        ...prevSelected.slice(exerciseIndex + 1),
      ]);
    }
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(exerciseData);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setExerciseData(items);
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="exercises">
        {(provided) => (
          <table
            className="day-table"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            <thead>
              <tr>
                <th colSpan="5" className="table-title">
                  Day {day + 1}:{" "}
                  <input
                    className="table-input title-input"
                    type="text"
                    placeholder={dayData.name}
                    value={dayData.name}
                    onChange={(e) => {
                      const name = e.target.value;
                      setDayName(name);
                    }}
                  />
                </th>
                <th className="table-title">
                  <button
                    title="Remove"
                    className="special-row-button"
                    onClick={() => handleDeleteDay(day, week)}
                  >
                    <MdDelete></MdDelete>
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th></th>
                <th>Exercise</th>
                <th>Tips</th>
                <th>Reps</th>
                <th>Weight</th>
                <th></th>
              </tr>
              {exerciseData.map((exercise, exerciseIndex) => (
                <Draggable
                  draggableId={`exercise-${exerciseIndex}`}
                  key={exerciseIndex}
                  index={exerciseIndex}
                >
                  {(provided) => (
                    <React.Fragment key={exerciseIndex}>
                      {exercise.type !== "exercise" && (
                        <tr
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <th className="special-exercise-title" colSpan="2">
                            {exercise.type}:{" "}
                            <input
                              className="table-input title-input"
                              type="text"
                              value={
                                exerciseData[exerciseIndex]?.typeTrainingName
                              }
                              placeholder={
                                exerciseData[exerciseIndex]?.typeTrainingName
                              }
                              onChange={(e) => {
                                const typeTrainingName = e.target.value;
                                setExerciseData((prevSelected) => [
                                  ...prevSelected.slice(0, exerciseIndex),
                                  {
                                    ...prevSelected[exerciseIndex],
                                    typeTrainingName,
                                  },
                                  ...prevSelected.slice(exerciseIndex + 1),
                                ]);
                              }}
                            />
                          </th>
                          <th className="head-color"></th>
                          <th className="head-color" colSpan="2">
                            Rounds:{" "}
                            <input
                              className="table-input small-table-input"
                              type="text"
                              placeholder={
                                exerciseData[exerciseIndex]?.rounds || "1"
                              }
                              onChange={(e) => {
                                const rounds = e.target.value || "0";
                                setExerciseData((prevSelected) => [
                                  ...prevSelected.slice(0, exerciseIndex),
                                  {
                                    ...prevSelected[exerciseIndex],
                                    rounds,
                                  },
                                  ...prevSelected.slice(exerciseIndex + 1),
                                ]);
                              }}
                            />
                          </th>
                          <th className="head-color">
                            {" "}
                            <button
                              title="Remove"
                              className="special-row-button"
                              onClick={() => deleteRow(exerciseIndex)}
                            >
                              <MdDelete></MdDelete>
                            </button>
                          </th>
                        </tr>
                      )}
                      {exercise.type !== "exercise" ? (
                        (exercise.children || []).map(
                          (specialExercise, specialIndex) => (
                            <React.Fragment key={specialIndex}>
                              <tr key={specialIndex}>
                                <td className="special-exercise">
                                  {specialIndex + 1}
                                </td>
                                <td className="special-exercise">
                                  <Select
                                    className="select-table-input"
                                    components={{
                                      IndicatorSeparator: () => null,
                                    }}
                                    value={
                                      exerciseData[exerciseIndex]
                                        ? {
                                            label:
                                              exerciseData[exerciseIndex]
                                                .children[specialIndex].name,
                                            value:
                                              exerciseData[exerciseIndex]
                                                .children[specialIndex].dbId,
                                          }
                                        : null
                                    }
                                    onChange={(selectedOption) =>
                                      onChangeSpecialExercise(
                                        selectedOption,
                                        exerciseIndex,
                                        specialIndex
                                      )
                                    }
                                    options={sidebarItems
                                      .filter(
                                        (item) =>
                                          item.exercise &&
                                          item.exercise.type === "exercise"
                                      )
                                      .map((item) => ({
                                        label: item.exercise
                                          ? item.exercise.name
                                          : "",
                                        value: item.exercise
                                          ? item.exercise.dbId
                                          : "",
                                      }))}
                                  />
                                </td>
                                <td className="special-exercise">
                                  <textarea
                                    className="table-input tips-table-input"
                                    type="text"
                                    value={
                                      exerciseData[exerciseIndex].children[
                                        specialIndex
                                      ]?.extraTips || ""
                                    }
                                    placeholder={
                                      exerciseData[exerciseIndex].children[
                                        specialIndex
                                      ]?.extraTips || ""
                                    }
                                    onChange={(e) => {
                                      const extraTips = e.target.value || "";
                                      setExerciseData((prevSelected) => [
                                        ...prevSelected.slice(0, exerciseIndex),
                                        {
                                          ...prevSelected[exerciseIndex],
                                          children: [
                                            ...prevSelected[
                                              exerciseIndex
                                            ].children.slice(0, specialIndex),
                                            {
                                              ...prevSelected[exerciseIndex]
                                                .children[specialIndex],
                                              extraTips,
                                            },
                                            ...prevSelected[
                                              exerciseIndex
                                            ].children.slice(specialIndex + 1),
                                          ],
                                        },
                                        ...prevSelected.slice(
                                          exerciseIndex + 1
                                        ),
                                      ]);
                                    }}
                                  />
                                </td>
                                <td className="special-exercise">
                                  <input
                                    className="table-input small-table-input"
                                    type="text"
                                    placeholder={
                                      exerciseData[exerciseIndex].children[
                                        specialIndex
                                      ]?.reps || "1"
                                    }
                                    onChange={(e) => {
                                      const reps = e.target.value || "1";
                                      setExerciseData((prevSelected) => [
                                        ...prevSelected.slice(0, exerciseIndex),
                                        {
                                          ...prevSelected[exerciseIndex],
                                          children: [
                                            ...prevSelected[
                                              exerciseIndex
                                            ].children.slice(0, specialIndex),
                                            {
                                              ...prevSelected[exerciseIndex]
                                                .children[specialIndex],
                                              reps,
                                            },
                                            ...prevSelected[
                                              exerciseIndex
                                            ].children.slice(specialIndex + 1),
                                          ],
                                        },
                                        ...prevSelected.slice(
                                          exerciseIndex + 1
                                        ),
                                      ]);
                                    }}
                                  />
                                </td>
                                <td className="special-exercise">
                                  <div className="tr-flex">
                                    <input
                                      className="table-input small-table-input"
                                      type="text"
                                      placeholder={
                                        exerciseData[exerciseIndex].children[
                                          specialIndex
                                        ]?.weight || "0"
                                      }
                                      onChange={(e) => {
                                        const weight = e.target.value || "0";
                                        setExerciseData((prevSelected) => [
                                          ...prevSelected.slice(
                                            0,
                                            exerciseIndex
                                          ),
                                          {
                                            ...prevSelected[exerciseIndex],
                                            children: [
                                              ...prevSelected[
                                                exerciseIndex
                                              ].children.slice(0, specialIndex),
                                              {
                                                ...prevSelected[exerciseIndex]
                                                  .children[specialIndex],
                                                weight,
                                              },
                                              ...prevSelected[
                                                exerciseIndex
                                              ].children.slice(
                                                specialIndex + 1
                                              ),
                                            ],
                                          },
                                          ...prevSelected.slice(
                                            exerciseIndex + 1
                                          ),
                                        ]);
                                      }}
                                    />
                                    Kg
                                  </div>
                                </td>
                                <td className="special-exercise">
                                  <button
                                    title="Remove"
                                    className="special-row-button"
                                    onClick={() =>
                                      deleteSpecialRow(
                                        exerciseIndex,
                                        specialIndex
                                      )
                                    }
                                  >
                                    <MdDelete></MdDelete>
                                  </button>
                                </td>
                              </tr>
                              {specialIndex ===
                                (exercise.children || []).length - 1 && (
                                <tr>
                                  <td
                                    className="special-exercise"
                                    title="add new exercise"
                                    onClick={() => addSpecialRow(exerciseIndex)}
                                    colSpan="6"
                                  >
                                    <IoAddCircle className="icon-add-row" />
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          )
                        )
                      ) : (
                        <tr
                          key={exerciseIndex}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <td>{exerciseIndex + 1}</td>
                          <td>
                            <Select
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                              className="select-table-input"
                              options={sidebarItems
                                .filter((item) => item.exercise)
                                .map((item) => ({
                                  value: item.exercise.dbId,
                                  label: item.exercise.name,
                                }))}
                              value={{
                                value: exerciseData[exerciseIndex]?.dbId || "",
                                label:
                                  exerciseData[exerciseIndex]?.name ||
                                  "",
                              }}
                              onChange={(selectedOption) =>
                                onChangeMainExercise(
                                  selectedOption,
                                  exerciseIndex
                                )
                              }
                            />
                          </td>
                          <td>
                            <textarea
                              className="table-input tips-table-input"
                              type="text"
                              value={
                                exerciseData[exerciseIndex]?.extraTips || ""
                              }
                              placeholder={
                                exerciseData[exerciseIndex]?.extraTips || ""
                              }
                              onChange={(e) => {
                                const extraTips = e.target.value || "";
                                setExerciseData((prevSelected) => [
                                  ...prevSelected.slice(0, exerciseIndex),
                                  {
                                    ...prevSelected[exerciseIndex],
                                    extraTips,
                                  },
                                  ...prevSelected.slice(exerciseIndex + 1),
                                ]);
                              }}
                            />
                          </td>
                          <td>
                            <input
                              className="table-input small-table-input"
                              type="text"
                              placeholder={
                                exerciseData[exerciseIndex]?.reps || "1"
                              }
                              onChange={(e) => {
                                const reps = e.target.value || "1";
                                setExerciseData((prevSelected) => [
                                  ...prevSelected.slice(0, exerciseIndex),
                                  {
                                    ...prevSelected[exerciseIndex],
                                    reps,
                                  },
                                  ...prevSelected.slice(exerciseIndex + 1),
                                ]);
                              }}
                            />
                          </td>
                          <td>
                            <div className="tr-flex">
                              <input
                                className="table-input small-table-input"
                                type="text"
                                placeholder={
                                  exerciseData[exerciseIndex]?.weight || "0"
                                }
                                onChange={(e) => {
                                  const weight = e.target.value || "0";
                                  setExerciseData((prevSelected) => [
                                    ...prevSelected.slice(0, exerciseIndex),
                                    {
                                      ...prevSelected[exerciseIndex],
                                      weight,
                                    },
                                    ...prevSelected.slice(exerciseIndex + 1),
                                  ]);
                                }}
                              />
                              Kg
                            </div>
                          </td>
                          <td>
                            <button
                              title="Remove"
                              onClick={() => deleteRow(exerciseIndex)}
                            >
                              <MdDelete className="delete-icon"></MdDelete>
                            </button>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              <tr>
                <td title="add new exercise" colSpan="6">
                  <div className="button-group">
                    <button
                      onClick={() => addRow("exercise")}
                      className="add-exercise-button"
                    >
                      <IoAddCircle className="icon-add-row" /> Exercise
                    </button>
                    <button
                      onClick={() => addRow("circuit")}
                      className="add-exercise-button"
                    >
                      <IoAddCircle className="icon-add-row" /> Circuit
                    </button>
                    <button
                      onClick={() => addRow("auxilary")}
                      className="add-exercise-button"
                    >
                      <IoAddCircle className="icon-add-row" /> Auxilary
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DayProgram;
