import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ADD_DETAIL_HEADER from "../../components/Add_Detail_Header";
import Sidebar from "../../components/Menu/Sidebar";
import { AccountContext } from "../../routes/AccountContext";
import { SearchBar, filterPosts } from "../../components/SearchBar/SearchBar";
import PDFDROPZONE from "../../components/PdfDropzone/PdfDropzone";
import WeekProgram from "../../components/TrainingPrograms/WeekPrograms/WeekPrograms";

const TrainingPlanAdd = () => {
  const { state } = useLocation();

  const [planName, setPlanName] = useState(state?.fixedData?.planName || "");
  const [price, setPrice] = useState(state?.price || 0);
  const date = new Date().toISOString().substr(0, 10);
  const [startDate, setStartDate] = useState(date);
  const navigate = useNavigate();
  const { user } = useContext(AccountContext);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [uploadedPdfs, setUploadedPdfs] = useState([]);
  const [renderKey, setRenderKey] = useState(Date.now());

  const { search } = window.location;
  const query = new URLSearchParams(search).get("s");
  const [searchQuery, setSearchQuery] = useState(query || "");
  const filteredPosts = filterPosts(users, searchQuery, "member");

  let [training, setTraining] = useState(state.fixedData || null);

  const updateTrainingData = (week, day, dayName, updatedTrainingData) => {
    setTraining((prevTraining) => {
      const updatedTraining = { ...prevTraining };

      if (!updatedTraining.trainingData[week]) {
        updatedTraining.trainingData[week] = { theWeek: week, training: [] };
      }

      if (!updatedTraining.trainingData[week].training[day]) {
        updatedTraining.trainingData[week].training[day] = {
          day: day,
          children: [],
        };
      }

      updatedTraining.trainingData[week].training[day] = {
        ...updatedTraining.trainingData[week].training[day],
        name: dayName,
        children: updatedTrainingData,
      };

      return updatedTraining;
    });
  };

  const handleAddWeek = () => {
    setTraining((prevTraining) => {
      const updatedTraining = {
        ...prevTraining,
        trainingData: [
          ...(prevTraining?.trainingData || []),
          {
            theWeek: (prevTraining?.trainingData || []).length,
            training: [],
          },
        ],
      };
      return updatedTraining;
    });
  };

  const handleDeleteDay = (day, week) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this day?"
    );
    if (confirmed) {
      setTraining((prevTraining) => {
        const updatedTraining = { ...prevTraining };
        const updatedData = updatedTraining.trainingData[week];
        updatedData.training.splice(day, 1);
        updatedData.training.forEach((days, dayIndex) => (days.day = dayIndex));
        updatedTraining.trainingData[week] = updatedData;
        return updatedTraining;
      });

      setRenderKey(Date.now());
    }
  };

  const handleDeleteWeek = (week) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this week?"
    );
    if (confirmed) {
      setTraining((prevTraining) => {
        const updatedTraining = { ...prevTraining };

        updatedTraining.trainingData.splice(week, 1);

        updatedTraining.trainingData.forEach((currentWeek, weekIndex) => {
          currentWeek.theWeek = weekIndex;
        });

        return updatedTraining;
      });

      setRenderKey(Date.now());
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getMembers = async () => {
      setLoading(true);
      try {
        const route = user.user;
        const response = await fetch(
          `${process.env.REACT_APP_API_MAIN_URL}members/myMembers/${route}`,
          {
            method: "GET",
            headers: {
              authorization: `Bearer ${user?.token}`,
            },
            signal: signal,
          }
        );

        if (!signal.aborted) {
          const jsonData = await response.json();
          setUsers(jsonData);
          setSelectedUsers(jsonData.filter((u) => u.userid === state.userId));
          setLoading(false);
        }
      } catch (err) {
        if (!signal.aborted) {
          console.log(err.message);
          setLoading(false);
        }
      }
    };

    getMembers();

    return () => {
      abortController.abort();
    };
  }, [state.userId, user?.token, user.user]);

  useEffect(() => {}, [uploadedPdfs]);

  function handleUserSelect(user) {
    const isSelected = selectedUsers.some((u) => u.id === user.id);
    if (isSelected) {
      setSelectedUsers(selectedUsers.filter((u) => u.id !== user.id));
    } else {
      setSelectedUsers([...selectedUsers, user]);
    }
  }

  const [pdfs, setPdfs] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetchPdfs = async () => {
      try {
        const planId = state?.planId;
        if (planId) {
          const response = await fetch(
            `${process.env.REACT_APP_API_MAIN_URL}pdfs/${planId}`,
            {
              method: "GET",
              credentials: "include",
              headers: {
                authorization: `Bearer ${user?.token}`,
              },
            }
          );
          if (!signal.aborted) {
            if (response.ok) {
              const pdfData = await response.json();
              setPdfs(pdfData);
            } else {
              console.error("Failed to fetch PDFs:", response.statusText);
            }
          }
        }
      } catch (error) {
        console.error("Error fetching PDFs:", error);
      }
    };

    fetchPdfs();
    return () => {
      abortController.abort();
    };
  }, [state?.planId, user?.token]);

  const createTraining = async () => {
    setLoading(true);

    if (training.trainingData.length < 1) {
      alert("Please provide a training schedule.");
    } else {
      try {
        let copy = { ...training };
        const data = copy.trainingData;

        if (selectedUsers <= 0) {
          postSchedule(data, null, true);
          alert("The schedule is saved in schedules for sale");
        } else {
          postForAll(data, false);
        }
      } catch (err) {
        setLoading(false);
        alert("There went something wrong");
      }
    }
    setLoading(false);
  };

  const postForAll = async (data, editMode) => {
    let isLast = 0;
    if (
      state.planId &&
      selectedUsers[selectedUsers.length - 1].userid === state.userId
    ) {
      isLast++;
    }
    selectedUsers.forEach((selectedUser) => {
      const forUser = selectedUser.userid;
      isLast++;
      if (
        state.planId &&
        selectedUsers[selectedUsers.length - 1].userid === state.userId
      ) {
      } else {
        if (editMode && state.userId === selectedUser.userid) {
        } else {
          postSchedule(data, forUser, selectedUsers.length === isLast);
        }
      }
    });
  };

  const postSchedule = async (data, forUser, last) => {
    let completedSuccessful = true;

    fetch(`${process.env.REACT_APP_API_MAIN_URL}trainingPrograms`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${user?.token}`,
      },
      body: JSON.stringify({
        adminId: user.user,
        planName,
        startDate,
        price,
        forUser,
        data,
      }),
    })
      .then((response) => {
        response.status !== 200 && (completedSuccessful = false);
        return response.json(); // Parse the response body as JSON
      })
      .then((responseData) => {
        if (uploadedPdfs.length > 0) {
          postPDFs(responseData.id);
        }
      })
      .then(() => {
        setLoading(false);
        !completedSuccessful && alert("There went something wrong");
        if (completedSuccessful && last)
          !state.userId ? navigate("/trainingPrograms") : navigate("/members");
      });
  };

  const editTraining = async () => {
    const data = training.trainingData;

    setLoading(true);
    if (selectedUsers.length === 0 && state?.planId) {
      editOrPostOriginal(data, true);
    } else if (
      selectedUsers.length === 1 &&
      selectedUsers[0].userid === state?.userId
    ) {
      editOrPostOriginal(data, true);
    } else {
      let originalFound = false;

      selectedUsers.forEach((u) => {
        if (u.userid === state?.userId) originalFound = true;
      });
      if (originalFound) editOrPostOriginal(data, false);
      postForAll(data, originalFound);
    }
  };

  const editOrPostOriginal = async (data, last) => {
    try {
      const forUser = state?.userId;
      const planId = state?.planId;
      fetch(`${process.env.REACT_APP_API_MAIN_URL}trainingPrograms/${planId}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${user?.token}`,
        },
        body: JSON.stringify({
          adminId: user?.user,
          planId,
          planName,
          startDate,
          price,
          forUser,
          data,
        }),
      }).then(() => {
        if (uploadedPdfs.length > 0) {
          postPDFs(planId);
        }
        setLoading(false);
        if (last === true)
          !state.userId ? navigate("/trainingPrograms") : navigate("/members");
      });
    } catch (err) {
      setLoading(false);
    }
  };

  const handlePdfUpload = (pdfFile) => {
    setUploadedPdfs(pdfFile);
  };

  const postPDFs = async (trainingprogramId) => {
    const formData = new FormData();
    uploadedPdfs.forEach((pdfFile) => {
      formData.append(`pdfFiles`, pdfFile);
    });
    formData.append("trainingprogramId", trainingprogramId); // Append the training program ID

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_MAIN_URL}pdfs`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            // You're sending a FormData object, so don't manually set 'Content-Type'
            // 'Content-Type': 'multipart/form-data',
            authorization: `Bearer ${user?.token}`,
          },
          body: formData, // Use the FormData object as the request body
        }
      );

      if (!response.ok) {
        console.error("Failed to upload PDF:", response.statusText);
      }
    } catch (error) {
      console.error("Error uploading PDF:", error);
    }
  };

  const handleDeletePdf = async (filename) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_MAIN_URL}pdfs/${filename}`,
        {
          method: "DELETE",
          headers: {
            authorization: `Bearer ${user?.token}`,
          },
        }
      );

      if (response.ok) {
        setPdfs((prevPdfs) =>
          prevPdfs.filter((pdf) => pdf.filename !== filename)
        );
      } else {
        console.error("Failed to delete PDF:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting PDF:", error);
    }
  };

  return (
    <div className="layout">
      <Sidebar selected={state?.forSale ? "Training programs" : "Members"} />
      <div className="layout__content">
        <div className="layout__content-main">
          <ADD_DETAIL_HEADER title={"Let's create a training program"} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "20px",
                  paddingBottom: "25px",
                }}
              >
                {!state?.forSale && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <h4>Select a start date: </h4>
                    <input
                      type={"date"}
                      defaultValue={date}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <h4>Name: </h4>
                  <input
                    placeholder="Name"
                    value={planName}
                    onChange={(e) => setPlanName(e.target.value)}
                  />
                </div>

                {state?.forSale && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <h4>Price: €</h4>
                    <input
                      placeholder="0"
                      type={"number"}
                      onChange={(e) => setPrice(e.target.value)}
                    />
                  </div>
                )}
              </div>
              <PDFDROPZONE onPdfUpload={handlePdfUpload} />
              <div>
                <h3>Uploaded PDFs:</h3>
                <ul>
                  {pdfs.map((pdf, index) => (
                    <li
                      style={{
                        marginBottom: "10px",
                      }}
                      key={index}
                    >
                      <a
                        href={`${process.env.REACT_APP_API_MAIN_URL}pdfs/download-pdf/${pdf.filename}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {pdf.filename}
                      </a>
                      <button
                        onClick={() => handleDeletePdf(pdf.filename)} // Add the delete action here
                        style={{
                          marginLeft: "10px",
                          backgroundColor: "red",
                          color: "white",
                          border: "none",
                          padding: "5px 10px",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        Delete
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {!state?.forSale && (
              <div>
                <h5>Users for this scheme</h5>
                <div style={{ marginTop: "15px", marginBottom: "15px" }}>
                  <SearchBar
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    placeholder="Search by name"
                    btnOnClick={() => null}
                  />
                </div>
                <div
                  style={{
                    maxHeight: "150px",
                    overflow: "auto",
                  }}
                >
                  {users &&
                    filteredPosts &&
                    Object.values(filteredPosts).map((sideBarItem, index) => (
                      <div
                        key={sideBarItem.id}
                        style={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                          paddingBottom: " 5px",
                        }}
                      >
                        <input
                          type="checkbox"
                          style={{ height: "20px", width: "20px" }}
                          checked={selectedUsers.some(
                            (u) => u.id === sideBarItem.id
                          )}
                          onChange={() => handleUserSelect(sideBarItem)}
                        />
                        <label>
                          {sideBarItem.firstname + " " + sideBarItem.lastname}
                        </label>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
          <hr style={{ margin: "16px 0 16px" }} />
          <div>
            {training?.trainingData?.map((week, index) => {
              return (
                <WeekProgram
                  key={renderKey + index}
                  week={index}
                  weekData={week}
                  updateTrainingData={updateTrainingData}
                  handleDeleteDay={handleDeleteDay}
                  handleDeleteWeek={handleDeleteWeek}
                />
              );
            })}
            <div>
              <button
                style={{
                  marginTop: "15px",
                  height: "50px",
                  width: "200px",
                  color: "var(--main-color)",
                  backgroundColor: "var(--main-color-txt)",
                  boxShadow: "var(--box-shadow)",
                  borderRadius: "45px",
                  fontweight: "600",
                  display: "block",
                  textalign: "center",
                }}
                onClick={handleAddWeek}
              >
                Add Week
              </button>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <button
                onClick={() =>
                  state.planId ? editTraining() : createTraining()
                }
                style={{
                  marginTop: "15px",
                  height: "50px",
                  width: "200px",
                  color: "var(--main-color)",
                  backgroundColor: "var(--main-color-accent-light)",
                  boxShadow: "var(--box-shadow)",
                  borderRadius: "45px",
                  fontweight: "600",
                  display: "block",
                  textalign: "center",
                }}
              >
                Save{loading && <p>LOADING...</p>}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainingPlanAdd;
