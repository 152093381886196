import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import HEADER_COMPANY from "../../components/Members/Details/Header_Company";
import Sidebar from "../../components/Menu/Sidebar";
import { AccountContext } from "../../routes/AccountContext";

function TrainingPlanDetails() {
  const [trainingProgram, setTrainingProgram] = useState([]);
  const [info, setInfo] = useState([]);
  let params = useParams();
  const { user } = useContext(AccountContext);
  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;

    const getTrainingProgram = async () => {
      try {
        const route = params.trainingProgramId;
        const response = await fetch(
          `${process.env.REACT_APP_API_MAIN_URL}trainingPrograms/trainingProgram/${route}`,
          {
            method: "GET",
            headers: {
              authorization: `Bearer ${user?.token}`,
            },
          }
        );
        const jsonData = await response.json();

        if (isMounted) {
          setTrainingProgram(jsonData);
        }
      } catch (err) {
        console.log(err.message);
      }
    };

    const getInfo = async () => {
      try {
        const userId = user?.id;
        const response = await fetch(
          `${process.env.REACT_APP_API_MAIN_URL}about/${userId}`,
          {
            method: "GET",
            headers: {
              authorization: `Bearer ${user?.token}`,
            },
          }
        );
        const jsonData = await response.json();

        if (isMounted) {
          setInfo(jsonData);
        }
      } catch (err) {
        console.log(err.message);
      }
    };

    getTrainingProgram();
    getInfo();

    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, [params.trainingProgramId, user?.token]);

  const editTrainingPlan = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_MAIN_URL}trainingPrograms/fullTrainingProgram/${params.trainingProgramId}`,
        {
          method: "GET",
          headers: {
            authorization: `Bearer ${user?.token}`,
          },
        }
      );
      const jsonData = await response.json();

      navigate("/trainingProgram/add", {
        state: {
          userId: state?.member?.userid,
          fixedData: jsonData,
          planId: params.trainingProgramId,
          forSale: state?.forSale || false,
        },
      });
    } catch (err) {
      //console.log(err.message);
    }
  };

  return (
    <div className="layout">
      <Sidebar selected={state?.forSale ? "Training programs" : "Members"} />
      <div className="layout__content">
        <div className="layout__content-main">
          <HEADER_COMPANY
            title={info.company?.name}
            subTitle={"Admin: " + user.userName}
            info={"Weeks: " + trainingProgram.length}
          />
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
              }}
            >
              <h2>{trainingProgram.name}</h2>
              <button className="editButton" onClick={() => editTrainingPlan()}>
                Edit schedule
              </button>
            </div>

            <div className="tableWrapper">
              <div className="table_overflow">
                <table>
                  <tbody>
                    <tr>
                      <th>Week</th>
                      <th>Exercises</th>
                      {!state?.forSale && <th>Status</th>}
                      {!state?.forSale && <th>All comments</th>}
                    </tr>

                    {trainingProgram.map((post, i) => (
                      <tr
                        key={post.id}
                        onClick={() => {
                          const weekId = post.id;

                          navigate(`/weekPlan/${weekId}`, {
                            state: {
                              title: "Week " + post.id,
                              subTitle: post.startDate,
                              underSubTitle: post.status,
                              forSale: state?.forSale || false,
                            },
                          });
                        }}
                      >
                        <td>{i + 1}</td>
                        <td>{post.exercises}</td>
                        {!state?.forSale && <td>{post.status}</td>}
                        {!state?.forSale && <td>{post.comments}</td>}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrainingPlanDetails;
