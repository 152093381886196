import React from "react";
import { IoPersonRemoveSharp } from "react-icons/io5";
import { GoPerson } from "react-icons/go";
import { MdPassword } from "react-icons/md";
import { BsPencilFill } from "react-icons/bs";

import "./Header_Company.css";

const Header_Member = ({
  name,
  sport,
  injury,
  reset,
  add,
  remove,
  updateInjury,
  memberInjury,
  editOnClick,
  picture,
}) => {
  return (
    <div className="headerContainer">
      <div style={{ display: "flex", width: "80%" }}>
        {picture && (
          <div className="memberPicture">
            {picture ? (
              <img alt="picture" src={picture}></img>
            ) : (
              <GoPerson color="white" size={"150px"} />
            )}
          </div>
        )}
        <div className="info">
          <div>
            <h2>{name}</h2>
            <span onClick={() => reset()}>
              Reset Password <MdPassword />
            </span>
          </div>
          <div>
            <h3>Sport: {sport || "none"}</h3>
            <span onClick={() => updateInjury(memberInjury)}>
              Injury: {Array.isArray(injury) ? injury.join(", ") : "none"}
              <BsPencilFill style={{ marginLeft: "5px" }} />
            </span>
          </div>
        </div>
      </div>

      <div className="buttons">
        <div style={{ display: "flex", alignItems: "center" }}>
          <div id="edit" style={{ display: "flex", alignItems: "center" }}>
            <h4 onClick={editOnClick}>Edit</h4>
            <BsPencilFill style={{ marginLeft: "5px" }} />
          </div>
        </div>
        <IoPersonRemoveSharp
          id="delete"
          size={"30px"}
          onClick={() => {
            remove();
          }}
        />
        <button onClick={() => add()}>Add schedule</button>
      </div>
    </div>
  );
};

export default Header_Member;
