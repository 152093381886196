import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import './PdfDropzone.css';

function PdfDropzone({ onPdfUpload }) {
  const [uploadedPdfs, setUploadedPdfs] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    const newPdfs = acceptedFiles.filter((file) => file.type === 'application/pdf');

    if (newPdfs.length > 0) {
      setUploadedPdfs([...uploadedPdfs, ...newPdfs]);
      onPdfUpload([...uploadedPdfs, ...newPdfs]); // Pass the updated PDF array to the parent component
    } else {
      alert('Please upload only PDF files');
    }
  }, [uploadedPdfs, onPdfUpload]);

  // Function to remove a PDF from the array
  const removePdf = (index) => {
    const updatedPdfs = [...uploadedPdfs];
    updatedPdfs.splice(index, 1);
    setUploadedPdfs(updatedPdfs);
    onPdfUpload(updatedPdfs); // Pass the updated PDF array to the parent component
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'application/pdf': ['.pdf'] }, // Specify accepted file types
    multiple: true, // Allow multiple files to be dropped at a time
  });

  return (
    <div>
      <p>Upload Extra Programs:</p>
      <div {...getRootProps()} className="dropzoneStyles">
        <input {...getInputProps()} />
        <p>Drag &amp; drop PDF files here, or click to select one.</p>
      </div>
      {uploadedPdfs.length > 0 && (
        <div>
          <p>Uploade PDFs:</p>
          <ul>
            {uploadedPdfs.map((pdf, index) => (
              <li key={index}>
                {pdf.name}{' '}
                <button style={{color: 'red'}} onClick={() => removePdf(index)}>Remove</button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default PdfDropzone;
