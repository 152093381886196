import { useNavigate } from "react-router";

const { createContext, useState, useEffect } = require("react");

export const AccountContext = createContext();

const UserContext = ({ children }) => {
  const [user, setUser] = useState({
    user: localStorage.getItem("userid"),
    id: localStorage.getItem("id"),
    loggedIn: null,
    token: localStorage.getItem("token"),
  });

  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      await fetch(process.env.REACT_APP_API_AUTH_URL + `login`, {
        credentials: "include",
        headers: {
          authorization: `Bearer ${user?.token}`,
        },
      })
        .catch((err) => {
          setUser({ loggedIn: false });
          return;
        })
        .then((r) => {
          if (!r || !r.ok || r.status >= 400) {
            setUser({ loggedIn: false });
            return;
          }

          return r.json();
        })
        .then((data) => {
          if (!data) {
            setUser({ loggedIn: false });
            return;
          }

          setUser({ ...data });
          data.superAdmin ? navigate("/homeSuperAdmin") : navigate("/home");
        });
    };

    fetchData();
  }, []);

  return (
    <AccountContext.Provider value={{ user, setUser }}>
      {children}
    </AccountContext.Provider>
  );
};

export default UserContext;
