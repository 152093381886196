import React, { useState, useEffect, useContext } from "react";
import { SearchBar, filterPosts } from "../../components/SearchBar/SearchBar";
import Sidebar from "../../components/Menu/Sidebar";
import { useNavigate } from "react-router-dom";
import { AccountContext } from "../../routes/AccountContext";

import "../../assets/css/layout.css";
import "../../assets/css/table.css";
import "./Home.css";

import { BsPencilFill } from "react-icons/bs";
import { MdDelete } from "react-icons/md";

function HomeSuperAdmin() {
  const navigate = useNavigate();
  const { user } = useContext(AccountContext);
  const [coach, setCoach] = useState([]);
  const { search } = window.location;
  const query = new URLSearchParams(search).get("s");
  const [searchQuery, setSearchQuery] = useState(query || "");
  const filteredPosts = filterPosts(coach, searchQuery, "member");
  const [loading, setLoading] = useState(true);
  const [showDeleteMemberModal, setShowDeleteMemberModal] = useState(false);
  const [toDelete, setToDelete] = useState();
  const [reset, setReset] = useState(false);

  const deleteMember = async () => {
    try {
      fetch(`${process.env.REACT_APP_API_MAIN_URL}homeSuperAdmin/${toDelete}`, {
        method: "DELETE",
        credentials: "include",
        headers: {
          authorization: `Bearer ${user?.token}`,
        },
      }).then(() => {
        alert("The coach has been deleted");
        setReset(true);
      });
    } catch (err) {
      alert("There went something wrong");
    }
  };

  useEffect(() => {
    const getdata = async () => {
      try {
        const userId = user.user;
        const response = await fetch(
          `${process.env.REACT_APP_API_MAIN_URL}homeSuperAdmin/${userId}`,
          {
            method: "GET",
            headers: {
              authorization: `Bearer ${user?.token}`,
            },
          }
        );
        const jsonData = await response.json();
        setCoach(jsonData);
      } catch (error) {}
    };

    getdata();
    setLoading(false);
    setReset(false);
  }, [reset]);

  return loading ? (
    <h1>Loading...</h1>
  ) : (
    <div className="layout">
      <Sidebar selected="Home" />
      <div className="layout__content">
        <div className="layout__content-main">
          <h1 className="home-title">Admin Panel</h1>
          <div>
            <h2>Coaches on the platform</h2>
            <SearchBar
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              placeholder="Search by name"
              btnName="Add Coach"
              btnOnClick={() => navigate("/admins/add")}
            />
            <div className="tableWrapper">
              <div className="table_overflow">
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Company Name</th>
                      <th>Members</th>
                      <th>Edit/Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredPosts &&
                      filteredPosts.map((post, i) => (
                        <tr key={post.userid}>
                          <td>
                            {post.lastname} {post.firstname}
                          </td>
                          <td>{post.companyname}</td>
                          <td>{post.countmembers}</td>
                          <td>
                            <BsPencilFill
                              onClick={() =>
                                navigate(`/admins/edit/${post.userid}`)
                              }
                            />
                            <MdDelete
                              onClick={() => {
                                setToDelete(post.userid);
                                setShowDeleteMemberModal(true);
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {showDeleteMemberModal && (
            <div
              className="memberModal"
              onClick={() => setShowDeleteMemberModal(false)}
            >
              <div className="memberModal-content">
                <h2>Are you sure you want to delete this coach?</h2>
                <button
                  onClick={() => {
                    deleteMember();
                  }}
                >
                  Yes
                </button>
                <button className="modalUpdateButton">No</button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default HomeSuperAdmin;
