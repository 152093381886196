import React from "react";
import "./confirmModal.css";

const confirmModal = ({ title, handleYes, handleNo, setVisible }) => (
  <div className="confirmModal" onClick={() => setVisible(false)}>
    <div className="confirmModalContent">
      <h2>{title}</h2>
      <button onClick={() => handleYes()}>Yes</button>
      <button
        className="modalUpdateButton"
        onClick={() => (console.log(handleNo) && handleNo ? handleNo() : null)}
      >
        No
      </button>
    </div>
  </div>
);

export default confirmModal;
