import React from "react";
import "./StatusCard.css";
import Chart from "react-apexcharts";

const StatusCard = (props) => {
  return (
    <div className="card_container">
      <div className="card_info">
        <h4>{props.gauche ? props.value?.members || 0 : props.value || 0}</h4>
        <h5>{props.title}</h5>
        <span>{props.subtTile}</span>
      </div>
      {props.gauche && (
        <Chart
          type="radialBar"
          series={[props.value?.active || 0]}
          options={{
            colors: ["#20E647"],
            plotOptions: {
              radialBar: {
                hollow: {
                  margin: 0,
                  size: "70%",
                  background: "#293450",
                },
                track: {
                  dropShadow: {
                    enabled: true,
                    top: 2,
                    left: 0,
                    blur: 4,
                    opacity: 0.15,
                  },
                },
                dataLabels: {
                  name: {
                    offsetY: -10,
                    color: "#fff",
                    fontSize: "13px",
                  },
                  value: {
                    color: "#fff",
                    fontSize: "30px",
                    show: true,
                  },
                },
              },
            },
            fill: {
              type: "gradient",
              gradient: {
                shade: "dark",
                type: "vertical",
                gradientToColors: ["#87D4F9"],
                stops: [0, 100],
              },
            },
            stroke: {
              lineCap: "round",
            },
            labels: [""],
          }}
        />
      )}
    </div>
  );
};

export default StatusCard;
