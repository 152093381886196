import UserContext from "./routes/AccountContext";
import Views from "./routes/Views";

import("./components/Menu/Sidebar.css");

function App() {
  return (
    <UserContext>
      <Views />
    </UserContext>
  );
}

export default App;
