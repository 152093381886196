export const MainCategory = [
  "Release",
  "Mobility",
  "Stretch",
  "Activation",
  "Power",
  "Strength",
  "Correctives",
  "Coordination",
  "Speed",
  "Perinatal"
]

export const Release = [
  ];

export const Mobility = [
  "Ankle",
  "Hip",
  "Spine",
  "Shoulder"
]

export const Stretch = [
  "Groin",
  "Anterior",
  "Posterior",
  "Glutes",
  "Spine",
]

export const Activation = [
  "Lower",
  "Upper"
]

export const Power = [
  "Medecine ball",
  "Lower horizontal",
  "Lower vertical",
  "Lower lateral",
  "Upper",
  "Rotation"
]

export const Strength = [
  "Lower unilateral",
  "Lower bilateral",
  "Lower posterior chain",
  "Upper pull vertical",
  "Upper push vertical",
  "Upper push Horizontal",
  "Upper pull Horizontal",
  "Shoulders"
]

export const Correctives = [
  "Lower",
  "Upper",
  "Glutes",
  "Rotator Cuff",
  "Lateroflexion",
  "Anti-flexion",
  "Anti-extension",
  "Anti-Rotation",
  "Anti-Lateroflexion",
  "Hybrid"
]

export const Coordination = [
]

export const Speed = [
  "Lateral",
  "Change of direction",
  "Linear"
]

export const Perinatal = []

export const defaultTargets = []
